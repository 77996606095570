import React from "react";
import { SITE_NAME, HOME_URL } from "../AppConstants";

import { useHistory } from "react-router-dom";
import QRCodeCode from "@/Assets/imgs/Slyone_QR_code.png";
import GoogleIcon from "@/Assets/imgs/google.png";
import AppleStoreIcon from "@/Assets/imgs/apple.png";


const SubscribeSuccess = () => {
    const history = useHistory();
    return (
        <div className={"success-content flex-container w-md-full"}>
            <title>{SITE_NAME} | Subscribe Success</title>
            <div className={"success-title"}>Thank you!</div>
            <div className={"success-slogan"}>
                Your purchase was completed successfully.
            </div>
            <div className={"success-detail"}>
                You will receive an email confirmation shortly with the instructions on
                how to complete your account setup.
            </div>

            <div className={"download-tips"}>
                <img src={QRCodeCode} alt={"qr code"} />
                <div className={"download-content"}>
                    <div className={"download-title"}>DOWNLOAD THE {SITE_NAME} APP!</div>
                    <div className={"download-detail"}>
                        Download the app by scanning the QR code or visiting the app store
                        on your device. Sign in to your account and start using your new
                        number!
                    </div>
                </div>
            </div>

            <div className={"dashboard-block"}>
                <div
                    className={"dashboard-link d-flex justify-content-center align-items-center"}
                >
                    <div className="app-icon mx-3 cursor-pointer">
                        <img src={GoogleIcon} alt={"Google"} />
                    </div>
                    <div className="app-icon mx-3 cursor-pointer">
                        <img src={AppleStoreIcon} alt={"Apple"} />
                    </div>
                </div>
            </div>

            <div className={"success-extra"}>
                Anything amiss? Email us at{" "}
                <a className={"email-link"} href={`mailto:support@${HOME_URL}`}>
                    <u>support@{HOME_URL}</u>
                </a>
            </div>
        </div>
    );
};

export default SubscribeSuccess;

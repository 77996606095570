/*
Description: index.js is executed everytime when user accesses the Slyfone web app.
Each url to gw.slyfone.com/app/ hits this file. From here the app is
directed to the desired page.
*/

import React from "react";
import ReactDOM from "react-dom";
import { Route, BrowserRouter } from "react-router-dom";
import Login from "./Pages/Login";
import Signup from "./Pages/Signup";
import ForgotPassword from "./Pages/ForgotPassword";

import Footer from "./Components/Footer";
import ScrollToTop from "./Components/ScrollToTp";
import VerifyEmail from "./Pages/VerifyEmail";

import TagManager from "react-gtm-module";
import { GlobalLoading } from "react-global-loading";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import Popup from "./Components/Popup";
import { Provider as JProvider } from "jotai";
import withAuth from "./Components/withAuth";
import ChoosePlan from "./Pages/ChoosePlan";
import Payment from "./Pages/Payment";
import AddCredit from "./Pages/AddCredit";
import AreaCode from "./Pages/AreaCode";
import SubscribeSuccess from "./Pages/SubscribeSuccess";
import Calls from "./Components/Calls";
import Chats from "./Components/Chats";
import ManagePlans from "./Components/ManagePlans";
import AccountManage from "./Components/AccountManage";
import withDashboard from "./Components/withDashboard";
import {store} from "@/services";
import "@style/style.scss";
import "@style/custom.scss";
import "@style/responsive.scss";

const tagManagerArgs = {
  gtmId: "GTM-5M4S3J8",
};
TagManager.initialize(tagManagerArgs);

// Alert optional configuration
const options = {
  position: positions.MIDDLE,
  timeout: 1000 * 10,
  transition: transitions.FADE,
  containerStyle: {
    positions: "fixed",
    top: 0,
    width: 0,
    height: 0,
    zIndex: 1000,
  },
};

ReactDOM.render(
<JProvider store={store}>
    <AlertProvider template={Popup} {...options}>
      <BrowserRouter basename="/app">
        <ScrollToTop>
          <GlobalLoading />
          <>
            {/* Route to login page */}
            <Route exact path="/login" component={Login} />
            {/* Route to signup page */}
            <Route exact path="/register" component={Signup} />
            {/* Route to forgot password page */}
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/verify-email" component={VerifyEmail} />

            {/* Route to homepage/dashboard of the app */}
            {/* <Route exact path="/dashboard" component={withAuth(Dashboard)} /> */}

            {/* Routes for the internal pages */}
            <Route exact path="/pricing-page" component={withAuth(ChoosePlan)} />
            <Route exact path="/area-code" component={withAuth(AreaCode)} />
            <Route exact path="/subscribe_success" component={withAuth(SubscribeSuccess)} />
            <Route exact path="/checkout" component={withAuth(Payment)} />
            <Route exact path="/add-credit" component={withAuth(AddCredit)} />
            <Route exact path="/dashboard/account" component={withAuth(withDashboard(AccountManage))} />
            <Route exact path="/dashboard/chats" component={withAuth(withDashboard(Chats))} />
            <Route exact path="/dashboard/plan" component={withAuth(withDashboard(ManagePlans))} />
            <Route exact path="/dashboard/calls" component={withAuth(withDashboard(Calls))} />
            <Route exact path="/" component={Login} />
          </>
          {/* <Footer /> */}
        </ScrollToTop>
      </BrowserRouter>
    </AlertProvider>
  </JProvider>,
  document.getElementById("root")
);

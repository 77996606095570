import { osName } from "react-device-detect";
import { v4 } from "uuid";

import { fetchWithToken, fetchWithoutToken, store } from "./index";

import {
  ACTIVESUBSCRIPTIONS_ATOM,
  CALLS_ATOM,
  CHATS_ATOM,
  DEVICES_ATOM,
  PLANS_ATOM,
  PROFILE_ATOM,
  SUBSCRIPTION_ATOM,
  BLOCK_NUMBER_ATOM,
  BALANCE_ATOM
} from "../Context";
import { UUID_KEY } from "../AppConstants";

export const getProfile = (remote = false) => {
  let profile = store.get(PROFILE_ATOM);
  if (profile && profile !== "null" && profile !== "undefined" && !remote) {
    return Promise.resolve(profile);
  }

  return fetchWithToken(`/user/webprofile`).then(async (res) => {
    await store.set(PROFILE_ATOM, res);
    return Promise.resolve(res);
  });
};

export const getSubscription = (remote = false) => {
  let subscription = store.get(SUBSCRIPTION_ATOM)
  if (subscription && subscription !== "null" && subscription !== "undefined" && !remote) {
    return Promise.resolve(subscription);
  }

  return fetchWithToken(`/webgetsubscription`).then(async (res) => {
    if(res){
        await store.set(SUBSCRIPTION_ATOM, res);
      }
    return Promise.resolve(res);
  });
};



export const updateProfile = (first_name, last_name) => {
  let profile = store.get(PROFILE_ATOM);
  return fetchWithToken(`/user/webprofile/`, {
    method: "PUT",
    body: JSON.stringify({
      first_name,
      last_name,
    }),
  }).then((res) => {
    // update profile in session storage
    store.set(PROFILE_ATOM, { ...profile, first_name, last_name });
    return Promise.resolve(res);
  });
};


export const updateEmail = (email) => {
  let profile = store.get(PROFILE_ATOM);
  return fetchWithToken(`/webupdateemail`, {
      method: "POST",
      body: JSON.stringify({
          email,
      }),
  }).then((res) => {
      if (res.success) {
        store.set(PROFILE_ATOM, { ...profile, email });
      }
      return Promise.resolve(res);
  });
};

export const uploadGreetingVoicemail = (file) => {
  const formData = new FormData();
  formData.append("audiofile", file);
  return fetchWithToken(`/webvmgreeting/`, {
      method: "POST",
      body: formData,
  }).then((res) => {
      return Promise.resolve(res);
  });
};

export const getBlockedNumbers = (remote = false) => {
    let blockNumbers = store.get(BLOCK_NUMBER_ATOM);
    if (blockNumbers && blockNumbers.length > 0 && !remote) return Promise.resolve(blockNumbers);
  return fetchWithToken(`/webgetblockednumber`).then((res) => {
    if(res){
        store.set(BLOCK_NUMBER_ATOM, res);
    }
    return Promise.resolve(res);
  });
};

export const hideCalls = (id) => {
  return fetchWithToken(`/webhidecalls`, {
    method: "POST",
    body: JSON.stringify({ id: id }),
  }).then((res) => {
    return Promise.resolve(res);
  });
};

export const hideMessages = (id) => {
  return fetchWithToken(`/webhidemessages`, {
    method: "POST",
    body: JSON.stringify({ id: id }),
  }).then((res) => {
    return Promise.resolve(res);
  });
};

export const hideVoicemail = (id) => {
  return fetchWithToken(`/webhidevoicemail`, {
    method: "POST",
    body: JSON.stringify({ id: id }),
  }).then((res) => {
    return Promise.resolve(res);
  });
};

export const deleteChat = (id) => {
  return fetchWithToken(`/webdeletechat/${id}`).then((res) => {
    return Promise.resolve(res);
  });
};

export const saveBlockedNumbers = (numbers) => {
  return fetchWithToken(`/websaveblocknumber`, {
    method: "POST",
    body: JSON.stringify(numbers),
  }).then((res) => {
    return Promise.resolve(res);
  });
};

export const unblockedNumbers = (currentnumber) => {
  return fetchWithToken(`/webunblocknumber`, {
    method: "POST",
    body: JSON.stringify(currentnumber),
  }).then((res) => {
    return Promise.resolve(res);
  });
};


export const getDevices = (remote = false) => {
    let devices = store.get(DEVICES_ATOM);
    if (devices && devices.phone_number && !remote) return Promise.resolve(devices);
    return fetchWithToken(`/webphones`).then(async (res) => {
    let device = null;
    if(res && res.length > 0){
      device = res[0]
    }
    await store.set(DEVICES_ATOM, device);
    return Promise.resolve(device);
  });
};

export const getCalls = (remote= false) => {
  let calls = store.get(CALLS_ATOM);
  if(calls && calls.length > 0 && !remote) return Promise.resolve(calls);

  return fetchWithToken(`/webcalllog`).then(async (res) => {
    if (res && !res.failure){
      await store.set(CALLS_ATOM, res);
    }
    return Promise.resolve(res);
  });
};

export const getMessages = () => {
  return fetchWithToken(`/webgetmessages`).then( (res) => {
    return Promise.resolve(res);
  });
};

export const getNewMessages = (remote= false) => {
  const chats = store.get(CHATS_ATOM);
  if(chats && chats.length > 0 && !remote) return Promise.resolve(chats);

  return fetchWithToken(`/webgetallchatmessages`, {}).then(async (res) => {
    if(res && !res.failure){
      store.set(CHATS_ATOM, res.reverse());
    }
    return Promise.resolve(res);
  });
};


export const getVoicemail = () => {
  return fetchWithToken(`/webvmlog`).then((res) => {
    return Promise.resolve(res);
  });
};

export const updateDeviceName = (phone_number, phone_name) => {
  return fetchWithToken(`/phones/${phone_number}/`, {
    method: "PATCH",
    body: JSON.stringify({
      phone_name: phone_name,
    }),
  }).then((res) => {
    return Promise.resolve(res);
  });
};

export const testDevice = (phone_number) => {
  return fetchWithToken(`/phones/${phone_number}/send/`).then((res) => {
    return Promise.resolve(res);
  });
};

export const dialNumber = (source, destination) => {
  return fetchWithToken(`/phones/${source}/dial/${destination}/`).then(
    (res) => {
      return Promise.resolve(res);
    }
  );
};

export const activeSubscriptionDetail = (remote = false) => {
  let active = store.get(ACTIVESUBSCRIPTIONS_ATOM);
  if (active && !remote) return Promise.resolve(active);
  return fetchWithToken(`/websubscription/active`).then(async (res) => {
    if(res && res.length > 0){
        await store.set(ACTIVESUBSCRIPTIONS_ATOM, res);
    }
    return Promise.resolve(res);
  });
};

export const userPaymentDetails = () => {
  return fetchWithToken(`/user/profile/more/`).then((res) => {
    return Promise.resolve(res);
  });
};

export const cancelSubscription = (subscription_id) => {
  return fetchWithToken(`/websubscription/details/${subscription_id}`, {
    method: "DELETE",
  }).then((res) => {
    return Promise.resolve(res);
  });
};

export const getPlans = (remote = false) => {
  let plans = store.get(PLANS_ATOM);
  if (plans && plans.length > 0 && !remote) return Promise.resolve(plans);
  return fetchWithToken(`/plans/`).then((res) => {
    store.set(PLANS_ATOM, res)
    return Promise.resolve(res);
  });
};

export const userCancellationSurvey = (answer_one, answer_two, others) => {
  return fetchWithToken(`/user/webcancellationservey`, {
    method: "POST",
    body: JSON.stringify({
      answer_one: answer_one,
      answer_two: answer_two,
      others: others,
    }),
  }).then((res) => {
    return Promise.resolve(res);
  });
};

export const upgradeSubscription = (plan) => {
  return fetchWithToken(`/webupgradesubscription`, {
    method: "POST",
    body: JSON.stringify({
      plan,
    }),
  }).then((res) => {
    return Promise.resolve(res);
  });
};

export const renewSubscription = (plan) => {
  return fetchWithToken(`/webresubscription`, {
    method: "POST",
    body: JSON.stringify({
      plan,
    }),
  }).then((res) => {
    return Promise.resolve(res);
  });
};


export const subscribeTo = (plan) => {
  return fetchWithToken(`/websubscription`, {
    method: "POST",
    body: JSON.stringify({
      plan,
    }),
  }).then((res) => {
    return Promise.resolve(res);
  });
};

export const payAddon = () => {
  return fetchWithToken(`/webpurchasecredit`, {
    method: "POST",
    body: JSON.stringify({
      plan: "ten dollar",
    }),
  }).then((res) => {
    return Promise.resolve(res);
  });
};

export const getBalance = (remote = false) => {
  let balance = store.get(BALANCE_ATOM);
  if (balance && !remote) return Promise.resolve(balance);
  return fetchWithToken(`/webgetbalance`).then(res=> {
    if(res){
      store.set(BALANCE_ATOM, res.balance);
    }
    return Promise.resolve(res);
  });
};

export const getPhoneNumber = (phone_number) => {
  const phone_name = `${osName}`;
  return fetchWithToken(`/phones/webpurchase/${phone_number}`, {
    method: "POST",
    body: JSON.stringify({
      phone_name,
    }),
  }).then((res) => {
    return Promise.resolve(res);
  });
};

export const getPaymentHistory = () => {
  return fetchWithToken(`/paymenthistory/`).then((res) => {
    return Promise.resolve(res);
  });
};

export const signup = (details) => {
  const uniqueId = v4();
  sessionStorage.setItem(UUID_KEY, uniqueId);

  return fetchWithoutToken(`/user/newsignup`, {
    method: "POST",
    body: JSON.stringify({
      first_name: details.first_name,
      last_name: details.last_name,
      email: details.email,
      password: details.password,
      username: details.email,
      mac_address: uniqueId,
      device_type: `${osName}`,
    }),
  }).then((res) => {
    return Promise.resolve(res);
  });
};

export const delAccount = () =>{
  return fetchWithToken(`/user/webdelete`).then(res=>{
    return Promise.resolve(res);
  })
}

export const resendEmail = (token)=>{
  return fetchWithToken(`/account/resend-verification/`, {method: "POST"}, token).then((res) => {
    return Promise.resolve(res);
  });
}

export const sendPasswordResetLink = (email) => {
  return fetchWithoutToken(`/account/request-password-reset`, {
    method: "POST",
    body: JSON.stringify({
      email: email,
    }),
  }).then((res) => {
    return Promise.resolve(res);
  });
};



export const resetPassword = (bundle) => {
  return fetchWithoutToken(`/account/reset-password/`, {
    method: "POST",
    body: JSON.stringify({
      key: bundle.key,
      password: bundle.password,
    }),
  }).then((res) => {
    return Promise.resolve(res);
  });
};

export const changePassword = (new_password) => {
  return fetchWithToken(`/account/update-password`, {
    method: "POST",
    body: JSON.stringify({
      password: new_password,
    }),
  }).then((res) => {
    return Promise.resolve(res);
  });
};

export const getAreacode = (area_code) => {
  return fetchWithToken(`/websearchphonesavailable/${area_code}`).then(
    (res) => {
      return Promise.resolve(res);
    }
  );
};

export const portPhone = (phone_number) => {
  return fetchWithToken(`/portphone/${phone_number}`).then((res) =>
    Promise.resolve(res)
  );
};

export const stripePutMethod = (user_stripe_token) => {
  return fetchWithToken(`/user/webprofile/more`, {
    method: "PUT",
    body: JSON.stringify({
      user_stripe_token: user_stripe_token,
    }),
  }).then((res) => {
    return Promise.resolve(res);
  });
};

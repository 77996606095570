import React from "react";
import DropdownMenuIcon from "@icons/angle-down-2.svg";
import "../style/DropDownMenu.scss";
import OutsideClickHandler from "react-outside-click-handler";
const DropDownMenu = ({
  deleteItem,
  setActiveItem,
  type,
  blockContact,
  activeItem,
  curItem,
  isDisabled,
}) => {
  return (
    <div className="custom-dropdown-menu">
      <img
        onClick={() => {
          setActiveItem((pValue) => {
            if (pValue && curItem === pValue) {
              return undefined;
            } else return curItem;
          });
        }}
        className="menu-icon"
        src={DropdownMenuIcon}
        alt=""
      />
      {activeItem && activeItem === curItem && (
        <OutsideClickHandler
          onOutsideClick={() => {
            setActiveItem(undefined);
          }}
        >
          <div className="menu_wrapper">
            <div className="dropdown_menu_item" onClick={deleteItem}>
              Delete {type}
            </div>
            <div
              className={
                "dropdown_menu_item alert-color" +
                (isDisabled ? " disable-btn" : " ")
              }
              onClick={blockContact}
            >
              Block contact
            </div>
          </div>
        </OutsideClickHandler>
      )}
    </div>
  );
};

export default DropDownMenu;

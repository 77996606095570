import { atom } from "jotai";
import {
    ACTIVE_SUB_KEY,
    PROFILE_KEY,
    USER_INFO_KEY,
    SUBSCRIPTION_KEY,
    PLANS_KEY,
    DEVICES_KEY,
} from "../AppConstants";

const USER_STORE = atom(
    sessionStorage.getItem(USER_INFO_KEY) !== "null" &&
        sessionStorage.getItem(USER_INFO_KEY) !== "undefined"
        ? JSON.parse(sessionStorage.getItem(USER_INFO_KEY))
        : null
);
export const USER_ATOM = atom(
    (get) => get(USER_STORE),
    (get, set, userInfo) => {
        set(USER_STORE, userInfo);
        sessionStorage.setItem(USER_INFO_KEY, JSON.stringify(userInfo));
    }
);

const PROFILE_STORE = atom();
export const PROFILE_ATOM = atom(
    (get) => get(PROFILE_STORE),
    (get, set, profile) => {
        set(PROFILE_STORE, profile);
        sessionStorage.setItem(PROFILE_KEY, JSON.stringify(profile));
    }
);

const ACTIVE_STORE = atom();
export const ACTIVESUBSCRIPTIONS_ATOM = atom(
    (get) => get(ACTIVE_STORE),
    (get, set, active) => {
        set(ACTIVE_STORE, active);
        sessionStorage.setItem(ACTIVE_SUB_KEY, JSON.stringify(active));
    }
);

const SUBSCRIPTION_STORE = atom();
export const SUBSCRIPTION_ATOM = atom(
    (get) => get(SUBSCRIPTION_STORE),
    (get, set, subscription) => {
        set(SUBSCRIPTION_STORE, subscription);
        sessionStorage.setItem(SUBSCRIPTION_KEY, JSON.stringify(subscription));
    }
);

const PLANS_STORE = atom();
export const PLANS_ATOM = atom(
(get) => get(PLANS_STORE),
(get, set, subscription) => {
  set(PLANS_STORE, subscription);
  sessionStorage.setItem(PLANS_KEY, JSON.stringify(subscription));
});

const DEVICES_STORE = atom();
export const DEVICES_ATOM = atom(
    (get) => get(DEVICES_STORE),
    (get, set, device) => {
    set(DEVICES_STORE, device);
    sessionStorage.setItem(DEVICES_KEY, JSON.stringify(device));
    }
);

export const CALLS_ATOM= atom();

export const CHATS_ATOM = atom();

export const BALANCE_ATOM = atom();

export const BLOCK_NUMBER_ATOM = atom([]);

import React, { useEffect, useState } from "react";
import { PHONE_NUMBER_KEY, SITE_NAME } from "../AppConstants";
import { getAreacode, portPhone } from "../services/ApiService";
import { formatPhoneNumber } from "../Utils";
import { show, hide } from "react-global-loading";
import { useAlert } from "react-alert";
import AreaCodeImg from "@/Assets/imgs/areacode.png";
import PhoneNumberImg from "@/Assets/imgs/phonenumber.png";


const AreaCode = (props) => {
    const { history } = props;
    const alert = useAlert();

    const [nb, setNb] = useState([]);
    const [phoneNumList, setPhoneNumList] = useState(null);
    const [phoneNum, setPhoneNum] = useState("");
    const [ownNum, setOwnNum] = useState(false);
    const [inputNum, setInputNum] = useState("");

    const [nb1, nb2, nb3] = nb;
    const { area_code, note, failure, phone_numbers } = phoneNumList || {};

    useEffect(() => {
        show();
        sessionStorage.removeItem(PHONE_NUMBER_KEY);
        hide()
    }, []);

    const onSubmitPhone = (e) => {
        show();
        if (phoneNum && phoneNum !== "") {
            sessionStorage.setItem(PHONE_NUMBER_KEY, phoneNum);
            // send event to GTM
            window.dataLayer.push({ event: "confirm_number" });

            history.push("./pricing-page");
        } else {
            alert.error("No number Selected");
        }
        hide();
    };

    const onRadioChange = (e) => {
        setPhoneNum(e.target.value);
    };

    const handleInputNum = (e) => {
        const val = parseNumber(e.target.value);

        setInputNum(
            val.length <= 3
                ? val
                : "(" +
                val.slice(0, 3) +
                ")" +
                val.slice(3, 6) +
                (val.length > 6 ? "-" + val.slice(6, 10) : "")
        );
    };

    const onAreaCodeChange = (e, i) => {
        nb[i] = e.target.value.replace(/\D/g, "");
        setNb((prev) => [...nb]);
        if (nb[i] && e.target.nextSibling) e.target.nextSibling.focus();
        if (!e.target.value && e.target.previousSibling) {
            e.target.previousSibling.focus();
        }
    };

    const onCodeClick = (str) => setNb(str.split(""));

    const onSubmitAreacode = async () => {
        if ((!nb1 || !nb2 || !nb3) && (nb1 === "" || nb2 === "" || nb3 === "")) {
            alert.info("Please enter area code!");
            return;
        }

        show();
        const area_code = nb.join("");

        // send event to GTM
        window.dataLayer.push({ event: "confirm_area_code" });
        try {
            const res = await getAreacode(area_code);
            if (
                !res ||
                (res.note && res.note !== "") ||
                (res.failure && res.failure !== "")
            ) {
                alert.error(
                    res
                        ? res.note || res.failure
                        : "Area code unavailable, use the recommended area codes"
                );
            }
            setPhoneNumList(res);
        } catch (error) {
            console.log(error);
            alert.error(error.message || "Network Error");
        } finally {
            hide();
        }
    };

    const parseNumber = (num) => {
        return num.replace(/\D/g, "").replace(/\(\)-/, "").slice(0, 10);
    };

    const submitPortNumber = async () => {
        const realNumber = parseNumber(inputNum);
        if (!realNumber || realNumber.length < 10) {
            alert.info("Please Provide valid phone number.");
            return;
        }
        show();
        try {
            const res = await portPhone(realNumber);
            if (res && !res.failure) {
                sessionStorage.setItem(PHONE_NUMBER_KEY, realNumber);

                // send event to GTM
                window.dataLayer.push({ event: "confirm_number" });
                history.push("./pricing-page");
            } else {
                alert.error(res.failure);
            }
        } catch (error) {
            console.log(error);
            alert.error(error.message || "Network Error");
        } finally {
            hide();
        }
    };

    return (
        <div className="mb-5 py-2 flex-container">
            <title>{SITE_NAME} | Area Code</title>
            {phone_numbers ? (
                <div className={"row py-0 py-lg-5 m-0 pl-lg-5"}>
                    <div className={"col col-6 d-flex align-items-center justify-content-end fig-backgroud-col pr-5"}>
                        <div className="areacode-fig d-flex align-items-bottom fig-backgroud">
                            <img src={PhoneNumberImg} alt={""} />
                        </div>
                    </div>
                    <div className={"col col-6 d-flex align-items-flexstart sm-full-col mx-auto mt-0 mt-lg-5 pt-0 pt-lg-5 pl-5 pl-xs-5"}>
                        <div className="area-code-page phones-setup-page">
                            <h1>Select Your Number</h1>
                            <h2>Choose a number from these options:</h2>

                            <div className="center-form">
                                <ul className="phones-setup">
                                    {phone_numbers.map((n) => (
                                        <li key={n.phone_number}>
                                            <label>
                                                <input
                                                    type="radio"
                                                    value={n.phone_number}
                                                    checked={phoneNum === n.phone_number}
                                                    onChange={(e) => onRadioChange(e)}
                                                />
                                                <span>{formatPhoneNumber(n.phone_number)}</span>
                                            </label>
                                        </li>
                                    ))}
                                </ul>
                                <button className={"px-5"} onClick={() => onSubmitPhone()} disabled={!phoneNum}>
                                    Confirm
                                </button>
                            </div>
                            <div className={"back-to-area-code mx-auto mt-4"}>
                                Don’t see a number you like?{" "}
                                <a
                                    onClick={() => {
                                        setOwnNum(false);
                                        setPhoneNumList(null);
                                        setNb([]);
                                    }}
                                    style={{ color: "#000" }}
                                >
                                    <u>Try another area code.</u>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            ) : ownNum ? (
                <div className="area-code-page phones-setup-page">
                    <h1>Bring your existing number</h1>
                    <h2>
                        Enter the number you want to port to <b>{SITE_NAME}</b>
                    </h2>
                    <div className={"extra-number-container"}>
                        <input
                            className={"extra-number-input"}
                            type="text"
                            onChange={(e) => handleInputNum(e)}
                            value={inputNum}
                        />
                    </div>
                    <div className={"extra-number-text"}>
                        <div className={"extra-number-title"}>
                            PORT YOUR NUMBER TO {SITE_NAME}
                        </div>
                        <div className={"extra-number-content"}>
                            Port means that your number moves from your carrier to {SITE_NAME}
                            . You can then use the full set of {SITE_NAME} features without
                            having to change your number. Porting your number usually takes up
                            to 4 days to transfer. Your old service will continue to work
                            during the porting process. We will email you when the transfer is
                            completed.
                        </div>
                    </div>
                    <div
                        className={"extra-number-btn"}
                        onClick={() => submitPortNumber()}
                    >
                        Comfirm
                    </div>
                    <div onClick={() => setOwnNum(!ownNum)} className="extra-number">
                        Go back to pick a new number?
                    </div>
                </div>
            ) : (
                <div className={"row py-0 py-lg-5 pl-lg-5"}>
                    <div className={"col col-6 d-flex align-items-center justify-content-end fig-backgroud-col pr-5"}>
                        <div className="areacode-fig d-flex align-items-bottom fig-backgroud">
                            <img src={AreaCodeImg} alt={""} />
                        </div>
                    </div>
                    <div className={"col col-6 d-flex align-items-flexstart sm-full-col mx-auto mt-5 pt-0 pt-lg-5 pl-5"}>
                        <div className="area-code-page ">
                            <h1>Pick Your Area Code</h1>
                            <h2>Enter the area code that you want</h2>
                            <div className={"center-form"}>
                                <label className="area-code-field">
                                    {["0", "1", "2"].map((i) => (
                                        <input
                                            key={i}
                                            maxLength={1}
                                            type="text"
                                            value={nb[i]}
                                            onChange={(e) => onAreaCodeChange(e, i)}
                                        />
                                    ))}
                                </label>
                                <div>
                                    <h3>Suggested codes</h3>
                                    <div className="suggested-codes px-0">
                                        {area_code
                                            ? area_code.map((code) => {
                                                return (
                                                    <h4 key={code} onClick={() => onCodeClick(code)}>
                                                        {code}
                                                    </h4>
                                                );
                                            })
                                            : ["339", "508", "857"].map((code) => {
                                                return (
                                                    <h4 key={code} onClick={() => onCodeClick(code)}>
                                                        {code}
                                                    </h4>
                                                );
                                            })}
                                    </div>
                                </div>
                                <button className='px-5' onClick={onSubmitAreacode} disabled={!nb1 || !nb2 || !nb3}>
                                    Confirm
                                </button>
                                {/*<div onClick={() => setOwnNum(!ownNum)} className="extra-number">*/}
                                {/*    Or bring your own number?*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                </div>

            )}
        </div>
    );
};

export default AreaCode;

import React, {FC} from "react";
import {SITE_NAME, HOME_URL} from "../AppConstants";

import NavBar from "../Components/NavBar";
import {login} from "../services/AuthService";
import {hide, show} from "react-global-loading";
import {resendEmail} from "../services/ApiService";
import {useAlert} from "react-alert";

const VerifyEmail = (props) => {
    const {email, password} = props.location ? props.location.state : {};
    const alert = useAlert();
    const handleResendEmail = async ()=>{
        if (password && email) {
            show();
            try {
                const user = await login(email, password, res.salt);
                if(user.user_token){
                    const re = await resendEmail(user.user_token)
                    if(re.success){
                        alert.show("Verification email has been resent.");
                    }else{
                        alert.error("Verification email resending error.");
                    }
                }
            } catch (error) {
                console.log(error);
                alert.error(error.message || "Network Error");
            } finally {
                hide();
            }
        } else {
            alert.error("Please request with right params");
        }
    }

    return (
        <div className="flex-container">
            <title>{SITE_NAME} | Verify Your Email</title>
            <NavBar {...props} />

            <div className={"success-content"}>
                <div className={"success-title"}>Thank you!</div>
                <div className={"success-slogan"}>Please verify your email</div>
                <div className={"success-detail"}>
                    You're almost there! We sent an email to <b>{email}</b>
                </div>

                <div className={"download-tips"}>
                    <div className={"download-content"}>
                        <div className={"email-title"}>
                            We’ve sent an verification email to: <b>{email}</b>
                        </div>
                        <div className={"email-detail"}>
                            Click the link in your email to verify your account.
                            If you can’t find the email check your spam or junk folder.
                            Click <u role={"button"} style={{color: "#45A5C0"}} onClick={()=> handleResendEmail()}>here</u> to resend.
                        </div>
                    </div>
                </div>

                <div className={"success-extra mt-5"}>
                    Anything amiss? Email us at{" "}
                    <a className={"email-link"} href={`mailto:support@${HOME_URL}`}>
                        <u>support@{HOME_URL}</u>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default VerifyEmail;

import { fetchWithoutToken, store } from "./index";
import {
  ACTIVE_SUB_KEY,
  PROFILE_KEY,
  PHONE_NUMBER_KEY,
  TOKEN_KEY,
  SELECT_PLAN_NAME_KEY,
  UUID_KEY,
} from "../AppConstants";

import { ACTIVESUBSCRIPTIONS_ATOM, PROFILE_ATOM, USER_ATOM } from "../Context";

let user = store.get(USER_ATOM);

export const login = (username, password) => {
  sessionStorage.removeItem(UUID_KEY);
  // Get a token from api server using the fetch api
  return fetchWithoutToken(`/user/websignin/`, {
    method: "POST",
    body: JSON.stringify({
      username,
      password,
    }),
  }).then((res) => {
    if (res.user_token) {
      setToken(res.user_token);
      store.set(USER_ATOM, res);
      user = res;
    }
    return res;
  });
};

export const appleLogin = (idtoken, authcode) =>{
  return fetchWithoutToken("/webiosverifyidtoken", {
    method: "POST",
    body: JSON.stringify({
      idtoken,
      authcode,
    })
  }).then((res) => {
    if (res.user_token) {
      setToken(res.user_token);
      store.set(USER_ATOM, res);
      user = res;
    }
    return res;
  });
};

export const googleLogin = (idtoken) =>{
  return fetchWithoutToken(`/webgoogleverifyidtoken/${idtoken}`, {
    method: "POST",
  }).then((res) => {
    if (res.user_token) {
      setToken(res.user_token);
      store.set(USER_ATOM, res);
      user = res;
    }
    return res;
  });
}

export const facebookLogin = (idtoken) =>{
  return fetchWithoutToken(`/webfacebookverifyidtoken/${idtoken}`, {
    method: "POST",
  }).then((res) => {
    if (res.user_token) {
      setToken(res.user_token);
      store.set(USER_ATOM, res);
      user = res;
    }
    return res;
  });
}

export const setToken = (token) => {
  // Retrieves the user token from sessionStorage
  return sessionStorage.setItem(TOKEN_KEY, token);
};

export const getToken = () => {
  // Retrieves the user token from sessionStorage
  return user ? user.user_token : sessionStorage.getItem(TOKEN_KEY);
};

export const isTokenExpired = (token) => {
  // Need backgroud token generate method to decode token
  return false;
};

export const loggedIn = () => {
  // Checks if there is a saved token and it's still valid
  const token = getToken(); // Getting token from sessionStorage
  return !!token && !isTokenExpired(token);
};

export const verifyToken = (token) => {
  return fetchWithoutToken(`/user/verifytoken/${token}`).then((res) => {
    setToken(res.user_token);
    store.set(USER_ATOM, res);
    user = res;
    return Promise.resolve(res);
  });
};

export const logout = () => {
  // clean store
  store.set(USER_ATOM, null);
  store.set(PROFILE_ATOM, null);
  store.set(ACTIVESUBSCRIPTIONS_ATOM, null);
  // Clear user token and profile data from sessionStorage
  sessionStorage.removeItem("contacts.token");
  sessionStorage.removeItem(UUID_KEY);
  sessionStorage.removeItem(SELECT_PLAN_NAME_KEY);
  sessionStorage.removeItem(PHONE_NUMBER_KEY);
  sessionStorage.removeItem(ACTIVE_SUB_KEY);
  // clean user_info/token at last
  sessionStorage.removeItem(PROFILE_KEY);
  sessionStorage.removeItem(TOKEN_KEY);
  sessionStorage.clear();

  return Promise.resolve("Session Storage Cleaned");
};

import React from "react";

export const Popup = (props) => {
    const { style, options, message, close } = props;

    return (
        <div className={`popup`} style={style}>
            <div className="popup_inner text-center">
                <h1>{options.type.toUpperCase()}</h1>
                <p>{message}</p>
                <div className="pull-right buttons">
                    <div className="close_btn" onClick={close}>
                        OK
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Popup;

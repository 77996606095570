import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
    Elements,
    ElementsConsumer,
    PaymentRequestButtonElement
} from "@stripe/react-stripe-js";

import { SITE_NAME } from "../AppConstants";
import {
    payAddon,
    stripePutMethod,
} from "../services/ApiService";
import { show, hide } from "react-global-loading";
import { useAlert } from "react-alert";
import ApplePayIcon from "@icons/applepay.svg";
import CircleIcon from '@icons/circle.svg'
import CardIcon from '@icons/card.svg'
import StripeIcon from "@icons/stripe.svg";

const AddCreditForm = (props) => {
    const { history, stripe, elements } = props;
    const alert = useAlert();

    const [payment, setPayment] = useState("card")
    const [paymentRequest, setPaymentRequest] = useState(null);
    const [applePayToken, setApplePayToken] = useState(null);
    const [applePayer, setApplePayer] = useState(null);

    useEffect(() => {
        if (stripe) {
            const pr = stripe.paymentRequest({
                country: "US",
                currency: "usd",
                total: {
                    label: "$10 credit",
                    amount: 10 * 100,
                },
                requestPayerName: true,
                requestPayerEmail: true,
            });

            // Check the availability of the Payment Request API.
            pr.canMakePayment().then(result => {
                if (result && result.applePay) {
                    setPaymentRequest(pr);
                }
            });

            pr.on("token", (evt) => {
                setApplePayToken(evt.token.id)
                setApplePayer(
                    { email: evt.payerEmail, name: evt.payerName }
                )
                evt.complete("success");
            })
        }
    }, [stripe]);

    const handleSubmit = async (e) => {
        show();
        e.preventDefault();

        if (elements == null) {
            return;
        }
        try {
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: "card",
                card: elements.getElement(CardNumberElement),
            });

            if (!error) {
                const cardNumberElement = elements.getElement(CardNumberElement);

                const st = await stripe.createToken(cardNumberElement);
                if (st.error) {
                    alert.error("Stripe Error");
                } else {
                    const t = await stripePutMethod(st.token.id);
                    if (t.failure) {
                        alert.error(t.failure);
                    }
                    const a = await payAddon();
                    if (a.success) {
                        hide();
                        history.push("/dashboard/account");
                    } else {
                        alert.error(a.failure);
                    }
                }
            } else {
                alert.error(error.message);
            }
        } catch (error) {
            console.log(error);
            alert.error(error.message || "Network Error");
        } finally {
            hide();
        }
    };

    const handleApplePaySubmit = async () => {
        show();
        try {
            const t = await stripePutMethod(applePayToken);
            if ((t||{}).failure) {
                alert.error(t.failure);
                hide();
                return;
            }
            const a = await payAddon();

            if ((a||{}).success) {
                hide();

                history.push("/dashboard/account");
            } else {
                alert.error(a.failure);
            }
        } catch (error) {
            console.log(error);
            alert.error(error.message || "Network Error");
        } finally {
            hide();
        }
    }

    return (
        <div className="checkout-page flex-container">
            <title>
                {SITE_NAME} | Add Credit
            </title>
            <h1>Payment</h1>
            <div className={"checkout-content"}>
                <div className="checkout-box checkout-left">
                    <h2>Your {SITE_NAME} Purchase</h2>
                    <div className={"price"}>$10.00 Today</div>
                    <div className={"price-hint"}><u>1000 OUTBOUND CREDITS</u></div>
                    <div className={"price-detail"}>
                        Each credit is equivalent to either:
                    </div>
                    <div className={"price-list"}>
                        <div className={"price-item"}>
                            <img src={CircleIcon} alt={"price-list"} />
                            <span>1 text message</span>
                        </div>
                        <div className={"price-item"}>
                            <img src={CircleIcon} alt={"price-list"} />
                            <span>1-min call</span>
                        </div>
                    </div>
                </div>
                <div className="checkout-box checkout-right">
                    <h2>Choose How to Pay</h2>
                    <div className={"payment-list"}>
                        <img
                            className={payment === "card" ? "payment-active" : ""}
                            src={CardIcon}
                            alt={"credit card"}
                            onClick={() => setPayment("card")}
                        />
                        {/*  <img src={"./icons/paypal.svg"} alt={"paypal"} />*/}
                        {paymentRequest && <img
                            className={payment === "applepay" ? "payment-active" : ""}
                            src={ApplePayIcon}
                            alt={"apple pay"}
                            onClick={() => setPayment("applepay")}
                        />}
                    </div>
                    {
                        payment === "card" && <>
                            <div className={"payment-extra"}>Pay With Card</div>
                            <form onSubmit={handleSubmit}>
                                <CardNumberElement
                                  className={"border-grey inner-text-padding"}
                                  options={{placeholder: ""}}
                                />
                                <CardCvcElement
                                  className={"border-grey inner-text-padding"}
                                  options={{placeholder: "CVC"}}
                                />
                                <CardExpiryElement
                                  className={"border-grey inner-text-padding"}
                                  options={{placeholder: "MM / YY"}}
                                />
                                <button type="submit" disabled={!stripe} className="stripe-button">
                                    Pay
                                </button>
                            </form>
                            <div className={"power-by"}>
                                Powered by <img src={StripeIcon} alt={"stripe"} />{" "}
                            </div>
                        </>
                    }
                    {
                        payment === "applepay" && <>
                            <div className={"payment-extra"}>Pay With Apple Pay</div>
                            <div id={"applepay-container"} className={"mt-2"}>
                                {applePayToken && applePayer ?
                                    (<>
                                        <hr />
                                        <div style={{ margin: "18px 0", fontSize: "16px" }}>
                                            <span><b>Apple Pay</b>: {applePayer.email}</span>
                                            <a onClick={() => {
                                                setApplePayToken(null);
                                                setApplePayer(null);
                                            }} style={{ textDecoration: "underline", float: "right", fontSize: "14px", cursor: "pointer" }}>Cancel</a>
                                        </div>
                                        <div>
                                            <button type="button" disabled={!applePayToken} className="stripe-button" onClick={() => handleApplePaySubmit()}>
                                                Pay
                                            </button>
                                        </div>
                                        <hr />
                                    </>)
                                    :
                                    (paymentRequest && <PaymentRequestButtonElement options={{
                                        paymentRequest, style: {
                                            paymentRequestButton: {
                                                type: "default",
                                                theme: 'dark',
                                                height: '44px',
                                            },
                                        }
                                    }} className="w-100 my-2" />)}
                            </div>
                            <div className={"power-by"}>
                                Powered by <img src={StripeIcon} alt={"stripe"} />{" "}
                            </div>
                        </>
                    }
                </div>
            </div>
            <div className={"bottom-text"}>
                By clicking "Pay", you acknowledge that you agree to our terms of service and privacy policy.
                A {SITE_NAME} Subscription is an auto-renewing subscription.
                You authorize {SITE_NAME} to immediately begin charging the payment method provided at the indicated price,
                and to continue to do so monthly until you cancel. You may <u className="cursor-pointer">cancel</u> anytime by logging into your account. <b><u>No refunds or credits for partial months or years once the purchase is complete.</u></b>
            </div>
        </div>
    );
};

const InjectedAddCreditForm = (props) => (
    <ElementsConsumer>
        {({ stripe, elements }) => (
            <AddCreditForm stripe={stripe} elements={elements} {...props} />
        )}
    </ElementsConsumer>
);

const stripePromise = loadStripe("pk_live_t4sUqft4SMty12798X7HKjl2");

const AddCredit = (props) => (
    <Elements stripe={stripePromise}>
        <InjectedAddCreditForm {...props} />
    </Elements>
);

export default AddCredit;

import React, { useEffect, useState } from "react";
import {
  getBalance,
} from "../services/ApiService";
import { formatPhoneNumber } from "../Utils";
import { hide, show } from "react-global-loading";
import { useAtom } from "jotai";
import {
  ACTIVESUBSCRIPTIONS_ATOM,
  DEVICES_ATOM,
  PROFILE_ATOM,
  USER_ATOM,
  BALANCE_ATOM
} from "../Context";
import ArrowRight from "@icons/arrow-right.svg";
import UserIcon from "@icons/user-3.svg";
import UserActiveIcon from "@icons/user-9.svg";
import ChatIcon from "@icons/message.svg";
import ChatActiveIcon from "@icons/message_c.svg";
import CallIcon from "@icons/calls.svg";
import CallActiveIcon from "@icons/calls_c.svg";
import { logout } from "../services/AuthService";
import {useHistory} from "react-router-dom";

const SiteNav = () => {
  const history = useHistory();
  const pathname = history.location?.pathname
  const parts = pathname.split("/");
  const curMenu = parts[parts.length - 1];
  const [user, setUser] = useAtom(USER_ATOM);
  const [, setActive] = useAtom(ACTIVESUBSCRIPTIONS_ATOM);
  const [device, setDevice] = useAtom(DEVICES_ATOM);
  const [profile, setProfile] = useAtom(PROFILE_ATOM);
  const { email, first_name, last_name } = profile || {};
  const [subscriptions] = useAtom(ACTIVESUBSCRIPTIONS_ATOM);
  const [balance] = useAtom(BALANCE_ATOM);

  useEffect(async () => {
    try {
      await getBalance();
    } catch (error) {
        console.log(error);
    }
  }, []);

  const _signOutEvent = async () => {
    history.push("/login");
    const res = await logout();
    setUser(null);
    setActive(null);
    setDevice(null);
    setProfile(null);
  };

  const InitialsLetter = () => {
    if (first_name && last_name) {
      return first_name[0] + last_name[0];
    } else {
      return "";
    }
  };

  const RightNavbarOptions = () => {
    if (user && user !== "") {
      return (
        <div className="green-box">
          <span className="user-badge">{InitialsLetter()}</span>
        </div>
      );
    } else {
      return "";
    }
  };

  const formatPlanPrice = (mount, interval) => {
    let dollar = (mount / 100).toFixed(2);
    let _interval = interval;
    let arr = interval.split(" ");
    if (arr[0] === "1") {
      _interval = arr[1];
    }

    return (
      <div className="price">
        <span className="total_amount">${dollar}</span>
        <span>{` per ${_interval} `}</span>
      </div>
    );
  };

  return (
    <div className="left_wrapper">
      <div className="user_info">
        <div className="info_area">
          <div className="avater"> {RightNavbarOptions()}</div>
          <div className="info">
            <div className="firstname">{first_name}</div>
            <div className="lastname">{last_name}</div>
            <div className="email_text">{email}</div>
          </div>
        </div>
        <div className="logout arrow_btn" onClick={_signOutEvent}>
          <span>LOG OUT</span>
          <img src={ArrowRight} alt="" />
        </div>
      </div>
      <div className="number">
        <div className="inner">
          <div className="desc">Your US phone number</div>
          <div className="number_text">
            {device && "+1 " + formatPhoneNumber(device.phone_number)}
          </div>
        </div>
      </div>

      {/* nav part */}
      <div className="nav-wrapper">
        <div
          className={
            curMenu === "account"
              ? "custom-nav-item  custom-nav-item-active"
              : "custom-nav-item"
          }
          onClick={() => history.push("/dashboard/account")}
        >
          {curMenu === "account" && <div className="left-bar"></div>}
          <div className="nav-icon">
            <img
              src={curMenu === "account" ? UserActiveIcon : UserIcon}
              alt=""
            />
          </div>
          <div
            className={
              curMenu === "account" ? "nav-name text-active" : "nav-name"
            }
          >
            Manage account
          </div>
        </div>
        <div
          className={
            curMenu === "chats"
              ? "custom-nav-item  custom-nav-item-active"
              : "custom-nav-item"
          }
          onClick={() => history.push("/dashboard/chats")}
        >
          {curMenu === "chats" && <div className="left-bar"></div>}
          <div className="nav-icon">
            <img src={curMenu === "chats" ? ChatActiveIcon : ChatIcon} alt="" />
          </div>
          <div
            className={
              curMenu === "chats" ? "nav-name text-active" : "nav-name"
            }
          >
            Chats
          </div>
        </div>
        <div
          className={
            curMenu === "calls"
              ? "custom-nav-item  custom-nav-item-active"
              : "custom-nav-item"
          }
          onClick={() => history.push("/dashboard/calls")}
        >
          {curMenu === "calls" && <div className="left-bar"></div>}
          <div className="nav-icon">
            <img src={curMenu === "calls" ? CallActiveIcon : CallIcon} alt="" />
          </div>
          <div
            className={curMenu === "calls" ? "nav-name text-active" : "nav-name"}
          >
            Call history
          </div>
        </div>
      </div>

      <div className="plan">
        <div className="balance">
          <div className="balance_inner">
            <div className="label">REMAINING BALANCE</div>
            <div className="value">{"$" + balance||"0.0"}</div>
          </div>
        </div>
        {subscriptions && (
          <div className="plan_manage">
            <div className="plan_name">
              {subscriptions[0] ? subscriptions[0].plan_name_en : ""}
            </div>
            {subscriptions[0] && formatPlanPrice(
              subscriptions[0].new_total_amount,
              subscriptions[0].plan_interval
            )}
          </div>
        )}
        <div className="mange arrow_btn" onClick={() => history.push("/dashboard/plan")}>
          <span>MANAGE</span>
          <img src={ArrowRight} alt="" />
        </div>
      </div>
    </div>
  );
};

export default SiteNav;

import React, { useEffect, useState } from "react";
import {
    SITE_NAME,
    SELECT_PLAN_KEY,
    SELECT_PLAN_NAME_KEY,
} from "../AppConstants";
import { getPlans } from "../services/ApiService";
import { hide, show } from "react-global-loading";
import { useAtom } from "jotai";
import PlanBanner from "@/Assets/imgs/plan.png";
import SuccessCheck from "@icons/check.svg";
import { ReactComponent as C1 } from "@icons/c1.svg";
import { ReactComponent as C2 } from "@icons/c2.svg";
import { ReactComponent as C3 } from "@icons/c3.svg";
import { ReactComponent as C4 } from "@icons/c4.svg";
import { ReactComponent as C5 } from "@icons/c5.svg";
import { ReactComponent as Plus } from "@icons/plus.svg";
import { ReactComponent as Check } from "@icons/check.svg";
import { useAlert } from "react-alert";
import {DEVICES_ATOM, PLANS_ATOM, SUBSCRIPTION_ATOM} from "../Context";

const ChoosePlan = (props) => {
    const { history } = props;
    const alert = useAlert();

    const [plans, setPlans] = useAtom(PLANS_ATOM);
    const [addon, setAddon] = useState(false);
    const [device] = useAtom(DEVICES_ATOM);
    const [subscription] = useAtom(SUBSCRIPTION_ATOM);

    const [selPlan, setSelPlan] = useState(
        sessionStorage.getItem[SELECT_PLAN_NAME_KEY]
    );

    useEffect(async () => {
        if (!plans || plans.length === 0) {
            show();
            try {
                const plans = await getPlans();
                if (plans && !plans.failure) {
                    !selPlan && setSelPlan(plans[0].plan_name_en);
                    setPlans(plans.sort((a, b) => a.new_total_amount - b.new_total_amount));
                } else {
                    alert.error(plans.failure);
                }
            } catch (error) {
                console.log(error);
            } finally {
                hide();
            }
        } else {
            !selPlan && setSelPlan(plans[0].plan_name_en);
            setPlans(plans.sort((a, b) => a.new_total_amount - b.new_total_amount));
        }
    }, []);

    const selectPlan = () => {
        sessionStorage.setItem(
            SELECT_PLAN_KEY,
            JSON.stringify(plans.find((i) => i.plan_name_en === selPlan))
        );

        // send event to GTM
        window.dataLayer.push({ event: "add_to_cart" });

        history.push("./checkout", { addon: addon });
    };

    return (
        <div className="flex-container">
            <title>{SITE_NAME} | Pricing Page </title>
            <div className="row py-2 m-0 mb-5 pl-lg-5">
                <div className={"col col-6 d-flex align-items-center justify-content-end fig-backgroud-col pr-5"}>
                    <div className="areacode-fig d-flex align-items-bottom fig-backgroud">
                        <img src={PlanBanner} alt={"plans"} />
                    </div>
                </div>
                <div className="col col-6 sm-full-col pl-5 p-s-0">
                    <div className="pricing-section px-xs-1">
                        <h1>Choose a Plan</h1>
                        {(subscription && subscription.status === "transition" && device && device.phone_number) &&
                            <div className={"transition-warning max-width-400 w-100"} >
                          Your plan has expired since we could not process payment. To keep your number and renew your subscription please update your payment information
                        </div>}
                        <div className="pricing-selector">
                            {plans &&
                                plans.map((item, i) => (
                                    <div
                                        key={item.plan_id + i}
                                        className={`${selPlan === item.plan_name_en ? "plan-active" : ""
                                            } ${i + 1 === plans.length ? "annual-plan" : ""} plan-item`}
                                        onClick={() => setSelPlan(item.plan_name_en)}
                                    >
                                        <div className={"plan-text"}>
                                            <div className={"plan-type font-weight-bold text-uppercase"}>{item.plan_name_en}</div>
                                            {i + 1 === plans.length ? (
                                                <div>
                                                    ${item.new_total_amount / 100}/ year<span>*</span>
                                                </div>
                                            ) : (
                                                <div>
                                                    <div>
                                                        ${item.new_monthly_amount / 100}/ month<span>*</span>
                                                    </div>
                                                    <div style={{ fontSize: "16px" }}>
                                                        Total billed ${item.new_total_amount / 100}
                                                    </div>
                                                </div>
                                            )}
                                            <div className={"price-extra"}>
                                                *Billing automatically renews.
                                            </div>
                                        </div>
                                        <div className={"plan-checkbox"}>
                                            <img src={SuccessCheck} alt={"month check"} />
                                        </div>
                                    </div>
                                ))}
                            <div className="plan-item-addon" onClick={() => setAddon(!addon)} role="button">
                                <div className="addon-badge">Add-on</div>
                                <div className="addon-content row">
                                    <div className="addon-text">
                                        <h5>OUTBOUND CREDITS</h5>
                                        <div className='addon-detail'>$10 for 1000 credits</div>
                                    </div>
                                    <div className="addon-icon">
                                        {addon ? <Check /> : <Plus />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"list-view m-xs-0 p-s-0"}>
                        <div className={"list-item my-2"}>
                            <C1 className="mx-xs-1" />
                            <span>Private Mobile Number</span>
                        </div>
                        <div className={"list-item my-2"}>
                            <C2 className="mx-xs-1" />
                            <span>Voicemail Inbox</span>
                        </div>
                        <div className={"list-item my-2"}>
                            <C3 className="mx-xs-1" />
                            <span>Mute & Spam Blocking</span>
                        </div>
                        <div className={"list-item my-2"}>
                            <C4 className="mx-xs-1" />
                            <span>Caller ID with Name</span>
                        </div>
                        <div className={"list-item my-2"}>
                            <C5 className="mx-xs-1" />
                            <span>100 Free Outbound Credits</span>
                        </div>
                    </div>

                    <div className={"submit-btn"} onClick={() => selectPlan()}>
                        Continue
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChoosePlan;

import React from "react";
import "../style/EmptyStatus.scss";
import ChatEmptyIcon from "@icons/chat-page.svg";
import PhoneIcon from "@icons/phone.svg";

const Contexts = {
  unSelectChats: {
    icon: ChatEmptyIcon,
    desc: `Select a conversation to open the chat.
To send, or delete a message, you will need to download the app on your phone.`,
  },
  noChats: {
    icon: ChatEmptyIcon,
    desc: `You have no messages yet.
To send, or delete a message, you will need to download the app on your phone.`,
  },
  calls: {
    icon: PhoneIcon,
    desc: `To make or receive calls, you will need to download the app on your phone.`,
  },
  noCalls: {
    icon: PhoneIcon,
    desc: `You have no call history.
To make or receive calls, you will need to download the app on your phone.`,
  },
  voicemail: {
    icon: PhoneIcon,
    desc: `To make or receive calls, you will need to download the app on your phone.`,
  },
  noVoicemail: {
    icon: PhoneIcon,
    desc: `You have no voicemails.
To make or receive calls, you will need to download the app on your phone.`,
  },
};

const EmptyStatus = ({ type, full = false }) => {
  return (
    <div className={full ? "fscreen empty-wrapper" : "empty-wrapper"}>
      <div className="status-icon">
        <img src={Contexts[type].icon} alt="" />
      </div>
      <pre className="desc">{Contexts[type].desc}</pre>
      <button className="btn12">DOWNLOAD APP</button>
    </div>
  );
};

export default EmptyStatus;

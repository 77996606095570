import { useRef, useState, forwardRef, useImperativeHandle } from "react";
import UploadIcon from "@icons/upload.svg";
import FileIcon from "@icons/file.svg";
import CloseIcon from "@icons/trash.svg";

const Upload = forwardRef(({accept, onSelect}, ref) => {
    const inputRef = useRef();
    const [file, setFile] = useState(null);

    useImperativeHandle(ref, () => ({
        clear: () => setFile(null)
    }));

    const handleDelete = () => {
        setFile(null);
        onSelect(null);
    };

    return (
        <div className="info_card justify-content-center upload">
            <div className={`upload-box ${file ? "selected" : ""}`} onClick={file ? () => void 0 : () => inputRef.current.click()}>
                {file ? (
                    <div className="file-box">
                        <div className="d-flex align-items-center">
                            <img src={FileIcon} alt="file" />
                            <span>{file.name}</span>
                        </div>
                        <img src={CloseIcon} className="delete-icon" alt="delete" onClick={handleDelete} />
                    </div>
                ) : (
                    <>
                        <img src={UploadIcon} alt="upload" />
                        <span>Click to Upload</span>
                    </>
                )}
            </div>
            <input 
                ref={inputRef}
                type="file"
                accept={accept}
                onChange={e => {
                    if (e.target.files && e.target.files.length) {
                        const file = e.target.files[0];
                        setFile(file);
                        onSelect(file);

                        // clear input so it can take new value
                        e.target.value = "";
                    }
                }}
                style={{display: "none"}}
            />
        </div>
    );
});

Upload.displayName = "Upload";

export default Upload;

import React from "react";
import { SITE_NAME } from "@/AppConstants";
import SiteNav from "./SiteNav";
import "../style/Device.scss";

export default function withDashboard(Component) {
    return () => {
        return <div className="device flex-container">
            <title>{SITE_NAME} | Dashboard</title>

            <div className="site_nav">
                <SiteNav />
            </div>
            <div className="nav_context">
                <Component/>
            </div>
        </div>
    }
}

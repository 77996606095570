import React, { useEffect, useState } from "react";
import NavBar from "../Components/NavBar";
import { _checkPassword, validateEmail } from "../Utils";
import { appleLogin, facebookLogin, googleLogin, loggedIn, logout } from "../services/AuthService";
import { signup, getProfile, activeSubscriptionDetail } from "../services/ApiService";
import { show, hide } from "react-global-loading";
import { useAlert } from "react-alert";
import { useAtom } from "jotai";
import { ACTIVESUBSCRIPTIONS_ATOM, DEVICES_ATOM, PROFILE_ATOM, USER_ATOM } from "../Context";
import { SITE_NAME, APPLE_ID, FB_APP_ID, GG_APP_ID, REDIRECT_URI, HOME_URL, SELECT_PLAN_NAME_KEY } from "../AppConstants";
import { LoginSocialFacebook, LoginSocialApple, LoginSocialGoogle } from "reactjs-social-login";
import { ReactComponent as AppleIcon } from "@icons/apple.svg";
import { ReactComponent as GoogleIcon } from "@icons/google.svg";
import { ReactComponent as FacebookIcon } from "@icons/facebook.svg";
import UserIcon from "@icons/user.svg";
import LockIcon from "@icons/lock-2.svg";
import EyeIcon from "@icons/eye.svg";
import EyeOffIcon from "@icons/eye-off.svg";
import AtIcon from "@icons/at.svg";
import SignupImg from "@/Assets/imgs/signup.png";

const SignUp = (props) => {
    const { history } = props;
    const alert = useAlert();
    const [registerInfo, setRegisterInfo] = useState({});
    const [isReveal, setIsReveal] = useState(false);
    const [isCheckReveal, setCheckIsReveal] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [user, setUser] = useAtom(USER_ATOM);

    const [, setProfile] = useAtom(PROFILE_ATOM);
    const [, setDevice] = useAtom(DEVICES_ATOM);
    const [, setActive] = useAtom(ACTIVESUBSCRIPTIONS_ATOM);
    const { first_name, last_name, email, password, confirm_password } =
        registerInfo;

    useEffect(() => {
        if (loggedIn() && user) {
            history.push("/dashboard/account");
        }
        show();
        const params = new URLSearchParams(props.location.search);
        const email = params.get("email");
        const selected_plan = params.get("plan");
        if (selected_plan)
            sessionStorage.setItem(SELECT_PLAN_NAME_KEY, selected_plan);
        if (email) setRegisterInfo({ email });
        hide();
    }, []);

    const cleanup = () => {
        setUser(null);
        setDevice(null);
        setProfile(null);
        setActive(null);
        logout();
        sessionStorage.clear();
      };

    const handleInputChange = (e) => {
        setRegisterInfo((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const onToggle = (name = "isReveal") => {
        name === "isReveal"
            ? setIsReveal(!isReveal)
            : setCheckIsReveal(!isCheckReveal);
    };

    const onSubmitSignUp = async () => {
        show();

        if (validate()) {
            hide();
            return;
        }
        try {
            const res = await signup(registerInfo);
            if (res && !res.failure) {
                history.push("./verify-email", { email, password });
            } else {
                alert.error(
                    res.failure ||
                    "Unable to create an account, this email is already associated to another account."
                );
            }
        } catch (error) {
            console.log(error);
            alert.error(error.message || "Something went wrong");
        } finally {
            hide();
        }
    };

    const validate = () => {
        if (!first_name || !last_name || !email || !password || !confirm_password) {
            alert.info("Please fill out all required fields");
            return true;
        }
        if (isChecked === false) {
            alert.info("Agree the terms & conditions");
            return true;
        }
        if (!validateEmail(email)) {
            alert.info("Invalid Email Address");
            return true;
        }
        if (!_checkPassword(password) || !_checkPassword(confirm_password)) {
            alert.info("Password should at least 8 digits");
            return true;
        }
        if (password !== confirm_password) {
            alert.info("Password doesn't match");
            return true;
        }
        return false;
    };

    const handleLoginResult = async (user) => {
        if (user.user_token) {
            try {
                setUser(user);
                const profile = await getProfile(true);
                setProfile({ ...profile });
                const active = await activeSubscriptionDetail(true);
                if (active && active.length > 0) {
                    setActive([...active]);
                    history.push("/dashboard/account");
                } else if (active && !active.failure) {
                    history.push("/area-code");
                } else {
                    cleanup();
                    alert.error(active.failure);
                }
            } catch (error) {
                console.log(error);
                alert.error(error.message || "Network Error");
            } finally {
                hide();
            }
        } else {
          alert.error(user.failure || "Network Error");
          hide();
        }
      };

    return (
        <div className="signup">
            <title>{SITE_NAME} | Register</title>
            <NavBar {...props} />
            <div className="container flex-container">
                <div className="row m-0 mb-5 mt-2">
                    <div className={"col col-6 d-flex align-items-center fig-backgroud-col"}>
                        <div className="signup-fig d-flex align-items-center fig-backgroud">
                            <img src={SignupImg} />
                        </div>
                    </div>
                    <div className={"col col-6 sm-full-col"}>
                        <div className="row d-flex align-items-center my-2" style={{ minWidth: "80%" }}>
                            <div className="w-s-full mx-auto d-flex align-items-center py-3">
                                <div className="signup-form w-s-full">
                                    <div className="signup-heading-div mb-5">
                                        <h1 className="signup-heading">Sign up</h1>
                                    </div>
                                    <form>
                                        <ul className="order-block">
                                            <div className="row">
                                                <li className="col">
                                                    <div className="form-group">
                                                        <input
                                                            required
                                                            type="text"
                                                            className="form-control"
                                                            name="first_name"
                                                            placeholder="First Name"
                                                            onChange={handleInputChange}
                                                        />
                                                        <img
                                                            className="signup-icon"
                                                            src={UserIcon}
                                                            alt={"first-name"}
                                                        />
                                                    </div>
                                                </li>
                                            </div>
                                            <div className="row">
                                                <li className="col">
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            required
                                                            className="form-control"
                                                            name="last_name"
                                                            placeholder="Last Name"
                                                            onChange={handleInputChange}
                                                        />
                                                        <img
                                                            className="signup-icon"
                                                            src={UserIcon}
                                                            alt={"last-name"}
                                                        />
                                                    </div>
                                                </li>
                                            </div>
                                            <div className="row">
                                                <li className="col">
                                                    <div className="form-group">
                                                        <input
                                                            required
                                                            type="text"
                                                            className="form-control"
                                                            name="email"
                                                            placeholder="Email Address"
                                                            onChange={handleInputChange}
                                                        />
                                                        <img
                                                            className="signup-icon"
                                                            src={AtIcon}
                                                            alt={"email"}
                                                        />
                                                    </div>
                                                </li>
                                            </div>
                                            <div className="row">
                                                <li className="col col-12">
                                                    <div className="form-group">
                                                        <input
                                                            required
                                                            type={isReveal ? "text" : "password"}
                                                            className="form-control"
                                                            name="password"
                                                            placeholder="Password (at least 8 digits)"
                                                            onChange={handleInputChange}
                                                        />
                                                        <img
                                                            className="signup-icon"
                                                            src={LockIcon}
                                                            alt={"password"}
                                                        />
                                                        {password && password !== "" ? (
                                                            <img
                                                                className="input-icon3 signup-eye-icon"
                                                                alt={"password-holder"}
                                                                src={isReveal ? EyeIcon : EyeOffIcon}
                                                                onClick={() => onToggle()}
                                                            />
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                </li>
                                            </div>
                                            <div className="row">
                                                <li className="col">
                                                    <div className="form-group">
                                                        <input
                                                            required
                                                            type={isCheckReveal ? "text" : "password"}
                                                            className="form-control"
                                                            placeholder="Confirm Password"
                                                            name="confirm_password"
                                                            onChange={handleInputChange}
                                                        />
                                                        <img
                                                            className="signup-icon"
                                                            src={LockIcon}
                                                            alt={"check password"}
                                                        />
                                                        {confirm_password && confirm_password !== "" ? (
                                                            <img
                                                                className="input-icon3 signup-eye-icon"
                                                                alt={"confirm_password-holder"}
                                                                src={isCheckReveal ? EyeIcon : EyeOffIcon}
                                                                onClick={() => onToggle("isCheckReveal")}
                                                            />
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                </li>
                                            </div>
                                        </ul>

                                        {/* <button type="button" className="button">CREATE ACCOUNT</button> */}
                                        <div className="clear"></div>

                                        <div className="form-group form-check d-flex mb-4">
                                            <input
                                                type="checkbox"
                                                defaultChecked={isChecked}
                                                onChange={() => setIsChecked(!isChecked)}
                                                required="required"
                                            />

                                            <div className="checkmark-text">
                                                I accept the {" "}
                                                <span>
                                                    <a href="https://slyfone.com/terms.html">
                                                        {" "}
                                                        Terms and Conditions
                                                    </a>
                                                </span> {" "}of this service
                                            </div>
                                        </div>

                                        <div>
                                            <button
                                                type="button"
                                                className="button w-100 text-center"
                                                onClick={onSubmitSignUp}
                                            >
                                                Sign up
                                            </button>
                                        </div>

                                        <div className="d-flex align-items-center justify-content-center">
                                            <LoginSocialApple
                                                client_id={APPLE_ID}
                                                scope={'name email'}
                                                redirect_uri={`${REDIRECT_URI}/register`}
                                                onResolve={async ({ provider, data }) => {
                                                    if (data && data.authorization) {
                                                        const user = await appleLogin(data.authorization.id_token, data.authorization.code)
                                                        handleLoginResult(user)
                                                    } else {
                                                        alert.error("Authorize Error.");
                                                    }
                                                }}
                                                onReject={err => {
                                                    console.log(err);
                                                }}
                                            >
                                                <div className="cursor-pointer sso-block d-flex align-items-center apple-backgroud">
                                                    <AppleIcon />
                                                </div>
                                            </LoginSocialApple>
                                            {/*<LoginSocialFacebook*/}
                                            {/*    appId={FB_APP_ID}*/}
                                            {/*    fieldsProfile={*/}
                                            {/*        'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'*/}
                                            {/*    }*/}
                                            {/*    redirect_uri={`${REDIRECT_URI}/register`}*/}
                                            {/*    onResolve={async ({ provider, data }) => {*/}
                                            {/*        if (data && data.accessToken) {*/}
                                            {/*            const user = await facebookLogin(data.accessToken)*/}
                                            {/*            handleLoginResult(user)*/}
                                            {/*        } else {*/}
                                            {/*            alert.error("Authorize Error.");*/}
                                            {/*        }*/}
                                            {/*    }}*/}
                                            {/*    onReject={err => {*/}
                                            {/*        console.error(err);*/}
                                            {/*    }}*/}
                                            {/*>*/}
                                            {/*    <div className="cursor-pointer sso-block d-flex align-items-center facebook-backgroud">*/}
                                            {/*        <FacebookIcon />*/}
                                            {/*    </div>*/}
                                            {/*</LoginSocialFacebook>*/}
                                            <LoginSocialGoogle
                                                client_id={GG_APP_ID}
                                                redirect_uri={`${REDIRECT_URI}/register`}
                                                scope="openid profile email"
                                                discoveryDocs="claims_supported"
                                                typeResponse="idToken"
                                                access_type="offline"
                                                onResolve={async ({ provider, data }) => {
                                                    if (data && data.credential) {
                                                        const user = await googleLogin(data.credential)
                                                        handleLoginResult(user)
                                                    } else {
                                                        alert.error("Authorize Error.");
                                                    }
                                                }}
                                                onReject={err => {
                                                    console.log(err);
                                                }}
                                            >
                                                <div className="cursor-pointer sso-block d-flex align-items-center google-backgroud">
                                                    <GoogleIcon />
                                                </div>
                                            </LoginSocialGoogle>
                                        </div>

                                        <div className="row">
                                            <a className="col col-12 text-center a-text-white" href="./login">
                                                <h3>
                                                    Already have an account?
                                                    <div className="round-button-signup"><b><u>Log in</u></b></div>
                                                </h3>
                                            </a>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignUp;

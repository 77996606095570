import React, { useEffect, useState } from "react";
import {
    getToken,
    loggedIn,
    logout,
    verifyToken,
} from "../services/AuthService";
import { getProfile } from "../services/ApiService";
import NavBar from "./NavBar";
import { PROFILE_ATOM, USER_ATOM } from "../Context";
import { useAtom } from "jotai";
import { useAlert } from "react-alert";
import { show, hide } from "react-global-loading";
import { PHONE_NUMBER_KEY } from "@/AppConstants";
import {updateInfo} from "@/Utils";

export default function withAuth(AuthComponent) {
    return (props) => {
        const alert = useAlert();
        const [loading, setLoading]= useState(true);
        const [hasSub, setHasSub] = useState(false);
        const [user, setUser] = useAtom(USER_ATOM);
        const [profile] = useAtom(PROFILE_ATOM);

        useEffect(async () => {
            show();
            const { location, history } = props;
            const { pathname, search } = location;


            if (!loggedIn()) {
                const token = new URLSearchParams(search).get("token");
                if (pathname === "/area-code" && token) {
                    show();
                    try {
                      const res = await verifyToken(token);
                      if (res && !res.failure) {
                          setUser(res);
                          await getProfile(true);
                      } else {
                          alert.error(res.failure);
                          history.push("/login");
                      }
                    } catch (error) {
                        console.log(error);
                    } finally {
                      hide();
                      setLoading(false)
                    }
                } else {
                    history.push("/login");
                }
            } else {
                try {
                  const [p, subscribe, active, device] = await updateInfo();
                  if(!p){
                    logout();
                    history.push("/login");
                  }
                  const token = getToken();
                  if (token && token !== "undefined" && user && user.user_token) {
                      if(active && active.length > 0 && device) {
                          setHasSub(true);
                      }

                      if(device && device.phone_number){
                          // reuse existing number
                          sessionStorage.setItem(PHONE_NUMBER_KEY, device.phone_number);
                      }
                      if(!active || active.length ===0){
                          // no active subscription
                          if(!device || !device.phone_number) {
                            const phone_number = sessionStorage.getItem(PHONE_NUMBER_KEY);
                              // no existing number, choose a new number
                            if(!["/area-code"].includes(pathname) && !phone_number) {
                              history.push("/area-code");
                            }
                          }else{
                              // has existing number, choose plan
                            if(!["/pricing-page", "/checkout"].includes(pathname)){
                              history.push("/pricing-page");
                            }
                          }
                        }

                      if(["/pricing-page", "/checkout", "/area-code"].includes(pathname) && active && active.length > 0 && device && device.phone_number){
                          // all good, redirect to dashboard
                          history.push("/dashboard/account")
                      }

                      if(["/area-code"].includes(pathname) && device && device.phone_number){
                          // has existing number, redirect to choose plan
                          history.push("/pricing-page")
                      }
                  } else {
                      history.push("/login");
                  }
                } catch (error) {
                    console.log(error);
                } finally {
                  hide();
                  setLoading(false)
                }
            }
        }, []);

        return user && user.user_token && !loading ? (
            <>
                <NavBar {...props} className={location.pathname.includes("/dashboard") ? "w-md-header" : ""} hasSub={hasSub} />
                <AuthComponent {...props} user={user} profile={profile} />
            </>
        ) : <div style={{minHeight: "calc(100vh - 320px)"}}/>;
    };
}

import React, { useState } from "react";
import "../../style/Contact.scss";
import { useAlert } from "react-alert";
import { validateEmail } from "../../Utils";
import { HOME_URL } from "../../AppConstants";

function ContactUs(props) {
  const alert = useAlert();
  const { closePopup } = props;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [messages, setMessages] = useState("");

  const handleSubmit = (e) => {
    if (!!email && !!messages) {
      if (!validateEmail(email)) {
        alert.error("Please input a valid email");
        return;
      }
      const formData = new FormData();
      formData.append("contactus_name", name);
      formData.append("email", email);
      formData.append("message", messages);
      fetch(`https://${HOME_URL}/_contactus.php`, {
        method: "POST",
        body: formData,
      })
        .then((r) => {
          return r.json();
        })
        .then((json) => {
          if (json.success) {
            alert.success("Thanks! Your message has been submitted.");
          } else {
            alert.info("Something went wrong. Please try again later.");
          }
          closePopup();
        })
        .catch((e) => {
          alert.error("Something went wrong.");
          closePopup();
        });
    } else {
      alert.error("Please complete all fields.");
    }
  };

  return (
    <div className="ContactusFormSection">
      <div className="contactus">
        <div className="ContactusForm">
          <h1> CONTACT US</h1>

          <div className="contactus-form">
            <input
              className={"contactusName"}
              name={"contactus_name"}
              type={"text"}
              placeholder={"Contactus Name"}
              onChange={(e) => setName(e.target.value)}
            />
            <input
              name="email"
              type="email"
              required
              placeholder="Email"
              value={email}
              autoComplete="off"
              onChange={(e) => setEmail(e.target.value)}
            />

            <textarea
              rows="8"
              placeholder="Message"
              name="message"
              required
              value={messages}
              onChange={(e) => setMessages(e.target.value)}
            ></textarea>

            <div className={"contact-form-btn"} onClick={handleSubmit}>
              {" "}
              SEND MESSAGE
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;

import React, { useEffect, useState } from "react";
import {
  getCalls,
  hideCalls,
  saveBlockedNumbers,
} from "../services/ApiService";
import Moment from "react-moment";
import { useAtom } from "jotai";
import {
  CALLS_ATOM,
  DEVICES_ATOM,
} from "../Context";
import { hide, show } from "react-global-loading";
import { useAlert } from "react-alert";
import EmptyStatus from "./EmptyStatus";
import "../style/Calls.scss";
import CallIncomingIcon from "@icons/call-incoming.svg";
import CallOutIcon from "@icons/call-outgoing.svg";
import CallRejectIcon from "@icons/call-missed.svg";
import DropDownMenu from "./DropDownMenu";


const Calls = () => {
  const alert = useAlert();
  const [items] = useAtom(CALLS_ATOM);
  const [device] = useAtom(DEVICES_ATOM);
  const [calls, setCalls] = useState();
  const [activeCall, setActiveCall] = useState();
  useEffect(async () => {
    show();
    try {
      await getCallList();
    } catch (error) {
      console.log(error)
    } finally {
      hide();
    }
  }, []);

  const formatResult = (msg) => {
    let text = msg.replaceAll("_", " ");
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };

  const getCallList = async (remote) => {
    const calls = await getCalls(remote);
    if (calls.failure) {
      return alert.error(calls.failure);
    }
    makeCallHistory(calls);
  };

  const makeCallHistory = (calls) => {
    const arr = calls.reduce((pValue, curValue) => {
      const pNode = pValue.find(
        (item) => item.date === curValue.call_date.split(" ")[0]
      );
      if (pNode) {
        pNode.calls.push({
          ...curValue,
          target:
              curValue.call_from === device.phone_number
              ? curValue.call_to
              : curValue.call_from,
          status:
              curValue.call_from === device.phone_number
              ? "outgoing"
              : "incoming",
        });
      } else {
        pValue = [
          ...pValue,
          {
            date: curValue.call_date.split(" ")[0],
            calls: [
              {
                ...curValue,
                target:
                  curValue.call_from === device.phone_number
                    ? curValue.call_to
                    : curValue.call_from,
                status:
                    curValue.call_from === device.phone_number
                    ? "outgoing"
                    : "incoming",
              },
            ],
          },
        ];
      }
      return pValue;
    }, []);
    setCalls(arr);
  };

  const blockContact = async () => {
    if (!activeCall.id || activeCall.is_blocked) return;
    show();
    try {
      const res = await saveBlockedNumbers([
        { phone_number: activeCall.target },
      ]);

      if (res.success) {
        await getCallList(true);
        setActiveCall(undefined);
        alert.success("Contact has been blocked");
      } else {
        alert.error(res.failure);
      }
    } catch (error) {
      console.log(error);
    } finally {
      hide();
    }
  };

  const deleteCalls = async () => {
    if (!activeCall.id) return;
    show();
    try{
      const res = await hideCalls(activeCall.id);
      if (res.success) {
        await getCallList(true);
        setActiveCall(undefined);
      } else {
        alert.error(res.failure);
      }
    } catch (error) {
      console.log(error)
      alert.error(error?.message || "Network Error");
    } finally {
      hide();
    }
  };

  const formatTime = (time) => {
    if (time > 60) {
      return (time / 60).toFixed(0) + " min";
    }
    return time + " s";
  };

  return (
    <div id="calls-wrapper">
      {items && items.length ? (
        <>
          <div className="dashboard-sub-menu">
            <div className="sub-menu-title">CALL HISTORY</div>
            <div className="conversations">
              {calls && calls.length
                ? calls.map((_item, index) => {
                    return (
                      <div className="callsByDate" key={`${_item.date}-${index}`}>
                        <div className="time-line">
                          <Moment interval={0} format="DD MMM YYYY">
                            {_item.date}
                          </Moment>
                        </div>
                        {_item.calls.map((item) => {
                          return (
                            <div className="calls-item">
                              <div className="status">
                                <img
                                  src={
                                    item.hangup_cause !== "SUCCESS"
                                      ? CallRejectIcon
                                      : item.status === "incoming"
                                      ? CallIncomingIcon
                                      : CallOutIcon
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="detail">
                                <div className="target">
                                  {item.target}{" "}
                                  <DropDownMenu
                                    setActiveItem={setActiveCall}
                                    blockContact={blockContact}
                                    activeItem={activeCall}
                                    curItem={item}
                                    deleteItem={deleteCalls}
                                    type={"call history"}
                                    isDisabled={item.is_blocked}
                                  />
                                </div>
                                <div className="sub-desc">
                                  <span>
                                    {item.hangup_cause === "SUCCESS"
                                      ? formatTime(item.duration)
                                      : formatResult(item.hangup_cause)}
                                  </span>
                                  <span className="call-time">
                                    {
                                      <Moment interval={0} format="HH:mm">
                                        {item.call_date}
                                      </Moment>
                                    }
                                  </span>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })
                : ""}
            </div>
          </div>

          <div className="app-board">
            <EmptyStatus type="calls" />
          </div>
        </>
      ) : (
        <EmptyStatus type="noCalls" full />
      )}
    </div>
  );
};

export default Calls;

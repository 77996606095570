import {activeSubscriptionDetail, getDevices, getProfile, getSubscription} from "@services/ApiService";
import {PROFILE_STORE} from "@/Context";

export const _checkPassword = (p) => {
  return p && p.length >= 8;
};

export const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? " " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
};

export const validateEmail = (email) => {
  var re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const updateInfo = async (remote= false)=>{
    return await Promise.all([
      getProfile(remote),
      getSubscription(remote),
      activeSubscriptionDetail(remote),
      getDevices(remote)
    ]);
  }

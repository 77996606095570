import React, { useState, useEffect } from "react";
import NavBar from "../Components/NavBar";
import {
    getProfile,
} from "../services/ApiService";
import { show, hide } from "react-global-loading";
import { useAlert } from "react-alert";
import { SITE_NAME, APPLE_ID, FB_APP_ID, GG_APP_ID, REDIRECT_URI } from "../AppConstants";
import { appleLogin, facebookLogin, googleLogin, login, logout } from "../services/AuthService";
import { LoginSocialFacebook, LoginSocialApple, LoginSocialGoogle } from "reactjs-social-login";
import {
    ACTIVESUBSCRIPTIONS_ATOM,
    PROFILE_ATOM,
    USER_ATOM,
    DEVICES_ATOM,
    SUBSCRIPTION_ATOM
} from "../Context";
import { useAtom } from "jotai";
import { ReactComponent as AppleIcon } from "@icons/apple.svg";
import { ReactComponent as GoogleIcon } from "@icons/google.svg";
import { ReactComponent as FacebookIcon } from "@icons/facebook.svg";
import SlyfoneIcon from "@imgs/Slyfone_logo.png";
import AtIcon from "@icons/at.svg";
import LockIcon from "@icons/lock-2.svg";
import EyeIcon from "@icons/eye.svg";
import EyeOffIcon from "@icons/eye-off.svg";

const Login = (props) => {
    const { history } = props;
    const alert = useAlert();
    const [isReveal, setIsReveal] = useState(false);
    const [userinfo, setUserinfo] = useState({});

    const [, setUser] = useAtom(USER_ATOM);
    const [, setProfile] = useAtom(PROFILE_ATOM);
    const [, setDevice] = useAtom(DEVICES_ATOM);
    const [, setActive] = useAtom(ACTIVESUBSCRIPTIONS_ATOM);
    const [, setSubscription] = useAtom(SUBSCRIPTION_ATOM);

    const { password, username } = userinfo;

    useEffect(() => {
        cleanup();
    }, []);

    const cleanup = () => {
        hide();
        setUser(null);
        setDevice(null);
        setProfile(null);
        setActive(null);
        setSubscription(null);
        logout();
        sessionStorage.clear();
    };

    const onToggle = () => {
        setIsReveal(!isReveal);
    };

    const handleChange = (e) => {
        setUserinfo((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleFormSubmit = async () => {
        show();
        if (password && username) {
            const user = await login(username, password);
            handleLoginResult(user)
        } else {
            alert.error("Please fill out all required fields");
            hide();
        }
    };

    const handleLoginResult = async (user) => {
        if (!user.user_token) {
            alert.error(user.failure || "Something went wrong");
            hide();
            return;
        }
        setUser(user);
        try {
            const profile = await getProfile(true);
            if(profile && !profile.is_verified){
                cleanup();
                history.push("/verify-email", { email: username, password });
              }else{
                history.push("/dashboard/account");
            }
          } catch (error) {
            console.log(error)
            alert.error(error?.message || "Something went wrong");
          } finally {
            hide();
          }
    }


    return (
        <div className="signup">
            <title>{SITE_NAME} | Log In</title>
            <NavBar {...props} />
            <div className="flex-container main-content center">
                <div className="container">
                    <div className="row m-0">
                        <div className="login-form mx-auto">
                            <div>
                                <div className="center-logo pb-3">
                                    <img src={SlyfoneIcon} alt="logo" />
                                </div>
                                <h1 className="signup-heading text-center pb-5" style={{ fontSize: "35px", fontWeight: "bold" }}>Log In</h1>
                                <div className="order-block">
                                    <div className="row">
                                        <li className="col">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control-login1"
                                                    name="username"
                                                    placeholder="Email"
                                                    onChange={handleChange}
                                                />
                                                <img className="input-icon" src={AtIcon} alt="email" />
                                            </div>
                                        </li>
                                    </div>
                                    <div className="row">
                                        <li className="col">
                                            <div className="form-group">
                                                <input
                                                    className="form-control-login1"
                                                    placeholder="Password"
                                                    name="password"
                                                    type={isReveal ? "text" : "password"}
                                                    onChange={handleChange}
                                                />
                                                <img
                                                    className="input-icon"
                                                    src={LockIcon}
                                                    alt="password"
                                                />
                                                {password && password !== "" ? (
                                                    <img
                                                        className="input-icon3"
                                                        alt={"password-holder"}
                                                        src={isReveal ? EyeIcon : EyeOffIcon}
                                                        onClick={onToggle}
                                                    />
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </li>
                                    </div>

                                    <div className="row">
                                        <div className="col">
                                            <button
                                                className="button w-100 text-center"
                                                onClick={handleFormSubmit}
                                            >
                                                LOG IN
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {/* SSO Login*/}
                                <div className="d-flex align-items-center justify-content-center">
                                    <LoginSocialApple
                                        client_id={APPLE_ID}
                                        scope={'name email'}
                                        redirect_uri={`${REDIRECT_URI}/login`}
                                        onResolve={async ({ provider, data }) => {
                                            if (data && data.authorization) {
                                                const user = await appleLogin(data.authorization.id_token, data.authorization.code)
                                                handleLoginResult(user);
                                            } else {
                                                alert.error("Authorize Error.");
                                            }
                                        }}
                                        onReject={err => {
                                            console.log(err);
                                        }}
                                    >
                                        <div className="cursor-pointer sso-block d-flex align-items-center apple-backgroud">
                                            <AppleIcon />
                                        </div>
                                    </LoginSocialApple>
                                    {/*<LoginSocialFacebook*/}
                                    {/*    appId={FB_APP_ID}*/}
                                    {/*    fieldsProfile={*/}
                                    {/*        'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'*/}
                                    {/*    }*/}
                                    {/*    redirect_uri={`${REDIRECT_URI}/login`}*/}
                                    {/*    onResolve={async ({ provider, data }) => {*/}
                                    {/*        if (data && data.accessToken) {*/}
                                    {/*            const user = await facebookLogin(data.accessToken)*/}
                                    {/*            handleLoginResult(user)*/}
                                    {/*        } else {*/}
                                    {/*            alert.error("Authorize Error.");*/}
                                    {/*        }*/}
                                    {/*    }}*/}
                                    {/*    onReject={err => {*/}
                                    {/*        console.error(err);*/}
                                    {/*    }}*/}
                                    {/*>*/}
                                    {/*    <div className="cursor-pointer sso-block d-flex align-items-center facebook-backgroud">*/}
                                    {/*        <FacebookIcon />*/}
                                    {/*    </div>*/}
                                    {/*</LoginSocialFacebook>*/}
                                    <LoginSocialGoogle
                                        client_id={GG_APP_ID}
                                        redirect_uri={`${REDIRECT_URI}/login`}
                                        scope="openid profile email"
                                        discoveryDocs="claims_supported"
                                        typeResponse="idToken"
                                        access_type="offline"
                                        onResolve={async ({ provider, data }) => {
                                            if (data && data.credential) {
                                                const user = await googleLogin(data.credential)
                                                handleLoginResult(user)
                                            } else {
                                                alert.error("Authorize Error.");
                                            }
                                        }}
                                        onReject={err => {
                                            console.log(err);
                                        }}
                                    >
                                        <div className="cursor-pointer sso-block d-flex align-items-center google-backgroud">
                                            <GoogleIcon />
                                        </div>
                                    </LoginSocialGoogle>
                                </div>

                                <div className=" row">
                                    <div
                                        className="col col-12 text-center a-text-white cursor-pointer"
                                        onClick={() => history.push("./forgot-password")}
                                    >
                                        <h6>I forgot my password.</h6>
                                    </div>
                                    <div
                                        className="col col-12 text-center a-text-white cursor-pointer"
                                        onClick={() => history.push("./register")}
                                    >
                                        <h3>
                                            Don't have an account?{" "}
                                            <div className="round-button-signup"><b><u>Sign up</u></b></div>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;

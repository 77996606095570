import {createStore} from "jotai";
import { DOMAIN } from "../AppConstants";
import { getToken, logout } from "./AuthService";

const fetchWithoutToken = (url, options = { method: "GET" }) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  return fetch(`${DOMAIN}${url}`, {
    headers,
    ...options,
  })
    .then((res) => checkStatus(res))
    .then((response) => response.json())
    .catch(async (err) => {
      console.log(err);
      const t = (err.response && await err.response.text()) || null;
      return t ? JSON.parse(t) : { failure: "Internal Server Error" };
    });
};

const fetchWithToken = (url, options = { method: "GET" }) => {
  const token = getToken();
  if (!token) {
    return Promise.reject("No Token provied in session storage.");
  }
  // performs api calls sending the required authentication headers
  const headers = {
    Accept: "application/json",
    Authorization: "Token " + token,
  };

  if (!(options.body instanceof FormData)) {
    headers["Content-Type"] = "application/json";
}

  return fetch(`${DOMAIN}${url}`, {
    headers,
    ...options,
  })
    .then((res) => checkStatus(res))
    .then((response) => {
      if (options.method === "DELETE") {
        return { success: true };
      } else {
        return response.json();
      }
    })
    .catch(async (err) => {
      console.log(err);
      if (err.statusCode === 401) {
        if (window.location.pathname !== "/app/login") {
          logout().then((res) => window.location.replace("./login"));
        }
      }
      const t = (err.response && await err.response.text()) || null;
      return t ? JSON.parse(t) : { failure: "Internal Server Error" };
    });
};

const checkStatus = (res) => {
  // raises an error in case response status is not a success
  if (res.status >= 200 && res.status < 300) {
    // Success status lies between 200 to 300
    return res;
  } else {
    var error = new Error(res.statusText);
    error.statusCode = res.status;
    error.response = res;
    throw error;
  }
};

const store = createStore();

export { fetchWithoutToken, fetchWithToken, store };

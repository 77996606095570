import React, { useEffect, useState } from "react";
import {
  getNewMessages,
  saveBlockedNumbers,
  deleteChat,
} from "../services/ApiService";
import Moment from "react-moment";
import { hide, show } from "react-global-loading";
import { useAlert } from "react-alert";
import EmptyStatus from "./EmptyStatus";
import "../style/Chats.scss";
import CheckIcon from "@icons/check-font.svg";
import FailureIcon from "@icons/failure.svg";
import DropDownMenu from "./DropDownMenu";
import { formatPhoneNumber } from "../Utils";
import {useAtom} from "jotai";
import {CHATS_ATOM} from "../Context";

const Chats = () => {
  const alert = useAlert();
  // const [items, setItems] = useState();
  const [items] = useAtom(CHATS_ATOM);
  const [curCvs, setCurCvs] = useState({});
  const [activeChat, setActiveChat] = useState();

  useEffect(async () => {
    show();
    try {
      await getMessageList();
    } catch (error) {
      console.log(error)
    } finally {
      hide();
    }
  }, []);

  const getMessageList = async (remote = false) => {
    const messages = await getNewMessages(remote);
    if (messages.failure) {
      alert.error(messages.failure);
    }
  };

  const selectCvs = (item) => {
    setCurCvs(item);
  };

  const deleteSelChat = async () => {
    if (!activeChat.chat_id) return;
    show();
    try {
      const res = await deleteChat(activeChat.chat_id);
      if (res.success) {
        alert.success("Number has been deleted");
        await getMessageList(true);
        setActiveChat(undefined);
      } else {
        alert.error(res.failure);
      }
    } catch (error) {
      console.log(error);
    } finally {
      hide();
    }
  };

  const blockContact = async () => {
    if (!activeChat.chat_id || activeChat.is_blocked) return;
    show();
    try {
      const res = await saveBlockedNumbers([
        { phone_number: activeChat.third_party_number },
      ]);
      if (res.success) {
        await getMessageList(true);
        setActiveChat(undefined);
        alert.success("Contact has been blocked");
      } else {
        alert.error(res.failure);
      }
    } catch (error) {
      console.log(error);
    } finally {
      hide();
    }
  };

  return (
    <div id="chats-wrapper">
      {items && items.length ? (
        <>
          <div className="dashboard-sub-menu">
            <div className="sub-menu-title">CHATS</div>
            <div className="conversations">
              <div className="cvs">
                {items.length
                  ? items.map((item) => {
                      return (
                        <div
                          className={
                            curCvs.chat_id === item.chat_id
                              ? "cv-item cv-item-active"
                              : "cv-item"
                          }
                          onClick={() => selectCvs(item)}
                          key={item.chat_id}
                        >
                          {curCvs.chat_id === item.chat_id && (
                            <div className="left-bar"></div>
                          )}
                          <div className="infos">
                            <div className="source">
                              {formatPhoneNumber(item.third_party_number) || item.third_party_number}
                              <DropDownMenu
                                setActiveItem={setActiveChat}
                                blockContact={blockContact}
                                menuId={item.chat_id}
                                deleteItem={deleteSelChat}
                                activeItem={activeChat}
                                curItem={item}
                                type={"chat"}
                                isDisabled={item.is_blocked}
                              />
                            </div>
                            <div className="time">
                              <Moment interval={0} format="DD-M-YYYY">
                                {item.messages[0].timestamp}
                              </Moment>
                            </div>
                          </div>
                          <div className="recent-msg">
                            {item.messages[0].text}
                          </div>
                        </div>
                      );
                    })
                  : ""}
                <div className="bottom-border"></div>
              </div>
            </div>
          </div>

          <div className="app-board">
            {curCvs.chat_id ? (
              <>
                <div className="messager opreation-name">
                  {curCvs.third_party_number}
                </div>
                <div className="message-wrapper">
                  {curCvs.messages &&
                    curCvs.messages.length &&
                    curCvs.messages.map((item) => (
                      <div
                        className={!item.direction ? "floor" : "floor-r"}
                        key={item.message_id}
                      >
                        <div
                          className={
                            (item.media_type === "image/jpeg"
                              ? "img-msg "
                              : " ") +
                            (!item.direction
                              ? "message-bub message-bub-from"
                              : "message-bub message-bub-to")
                          }
                        >
                          <ol>
                            <div className="message-setup">
                              {item.media_type === "image/jpeg" ? (
                                <img
                                  className="img-context"
                                  src={item.media_url}
                                />
                              ) : (
                                ""
                              )}
                              <div>
                                {item.text}
                              </div>
                              <div className="msg-time">
                                <Moment
                                  interval={0}
                                  format="DD MMM YYYY | HH:mm"
                                >
                                  {item.timestamp}
                                </Moment>
                                {item.direction ? (
                                  <img
                                    className="check-icon"
                                    src={item.sent ? CheckIcon : FailureIcon}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </ol>
                        </div>
                      </div>
                    ))}
                </div>
              </>
            ) : (
              <EmptyStatus type="unSelectChats" />
            )}
          </div>
        </>
      ) : (
        <EmptyStatus type="noChats" full={true} />
      )}
    </div>
  );
};

export default Chats;

import React, { useEffect, useState, useRef } from "react";
import {
  updateProfile,
  updateEmail,
  uploadGreetingVoicemail,
  changePassword,
  getBlockedNumbers,
  delAccount,
  resendEmail,
  unblockedNumbers,
} from "../services/ApiService";
import { hide, show } from "react-global-loading";
import { useAlert } from "react-alert";
import { useAtom } from "jotai";
import { PROFILE_ATOM, USER_ATOM, BLOCK_NUMBER_ATOM } from "../Context";
import { _checkPassword, validateEmail } from "../Utils";
import DeleteAccount from "./DeleteAccount";

import PencilIcon from "@icons/pencil.svg";
import PencilActiveIcon from "@icons/pencil-2.svg";
import BanIcon from "@icons/ban-2.svg";
import BanActiveIcon from "@icons/ban-2-c.svg";
import TrashIcon from "@icons/trash.svg";
import TrashActiveIcon from "@icons/trash-2.svg";
import UserIcon from "@icons/user-3.svg";
import AtIcon from "@icons/at.svg";
import LockIcon from "@icons/lock-2.svg";
import ArrowRight from "@icons/arrow-right-w.svg";
import CheckIcon from "@icons/check-circle-2.svg";
import CheckActiveIcon from "@icons/check-circle.svg";

import Upload from "./Upload";
import "../style/AccountManage.scss";
import { login, logout, sessionSalt } from "../services/AuthService";

const AccountManage = (props) => {
  const { history } = props;
  const alert = useAlert();
  const [user] = useAtom(USER_ATOM);
  const [isReveal, setIsReveal] = useState(false);
  const [isCheckReveal, setCheckIsReveal] = useState(false);
  const [type, setType] = useState("detail");
  const [emailType, setEmailType] = useState("detail");
  const [profile] = useAtom(PROFILE_ATOM);
  const [passwords, setPasswords] = useState({});
  const [userInfo, setUserInfo] = useState(user);
  const [voicemailFile, setVoicemailFile] = useState();
  const [blockedNumbers] = useAtom(BLOCK_NUMBER_ATOM);
  const [showDelete, setShowDelete] = useState(false);
  const [selectNumbers, setSelectNumbers] = useState([]);
  const { email, first_name, last_name } = profile || {};
  const [curSubMenu, setSubMenu] = useState("edit");
  const { password, confirm_password } = passwords;
  const uploadRef = useRef();
  const inputRefs = useRef({});

  useEffect(async () => {
    show();
    try{
      await getBlockedNumbers();
    } catch (error) {
      console.log(error);
    } finally {
      hide();
    }

  }, []);

  const selectSubMenu = (key) => {
    setSubMenu(key);
  };

  const handleInputChange = (e) => {
    setUserInfo((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const clickDel = () => {
    setShowDelete(true);
  };

  const handleInputPwdChange = (e) => {
    setPasswords((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const selectNumber = (blockNumber) => {
    const idx = selectNumbers.findIndex(
      (item) => blockNumber.phone_number === item.phone_number
    );
    if (idx >= 0) selectNumbers.splice(idx, 1);
    else selectNumbers.push(blockNumber);
    setSelectNumbers([...selectNumbers]);
  };

  const saveInfo = async () => {
    const { first_name, last_name } = userInfo;
    if (first_name && last_name) {
      show();
      try{
        const res = await updateProfile(first_name, last_name);
        setType("detail");
        if (res) {
          // setProfile((prev) => ({ ...prev, first_name, last_name }));
        }
      } catch (error) {
        console.log(error);
        alert.error(error?.message || "Network Error");
      } finally {
        hide();
      }
    } else {
      alert.error("Please input required fields.");
      setType("detail");
    }
  };

  const saveEmail = async () => {
    const { email_address = email } = userInfo;
    const emailAddress = email_address?.trim();
    let errMsg = "";
    if (emailAddress === email) {
        errMsg = "Please input new email";
    }
    if (!validateEmail(emailAddress)) {
        errMsg = "Invalid Email Address";
    }
    if (!emailAddress) {
        errMsg = "Please input required fields.";
    }
    if (errMsg) {
        alert.error(errMsg);
        setEmailType("detail");
        errMsg = "";
        return;
    }
    show();
    try {
        const res = await updateEmail(emailAddress);
        if (!res.success) {
          alert.error(res.failure);
        }
      setEmailType("detail");
    } catch (error) {
        console.log(error);
        alert.error(error?.message || "Network Error");
    } finally {
        hide();
    }
};

const uploadVoicemail = async () => {
    if (!voicemailFile){
        alert.error("Please select an MP3 file first!");
        return;
    }
    show();
    try {
        const res = await uploadGreetingVoicemail(voicemailFile);
        if (res.success) {
            uploadRef.current.clear();
            setVoicemailFile(null);
            alert.success("Voicemail greeting has been uploaded");
        } else {
            alert.error(res.failure);
        }
    } catch (error) {
        console.log(error);
        alert.error(error?.message || "Network Error");
    } finally {
        hide();
    }
};

  const handleDeleteAccount = async () => {
    show();
    try{
      const res = await delAccount();
      if (res.success) {
        logout();
        history.push("/login");
      }
    } catch (error) {
      console.log(error)
      alert.error(error?.message || "Network Error");
    } finally {
      hide();
    }
  };

  const _resendEvent = async () => {
    if (password && email) {
      show();
      try{
        const user = await login(email, password, res.salt);
        if(user.user_token){
            const re = await resendEmail(user.user_token)
            if(re.success){
                alert.show("Verification email has been resent.");
            }else{
                alert.error("Verification email resending error.");
            }
          }
        } catch (error) {
          alert.error(error?.message || "Network Error");
        } finally {
          hide();
        }
    } else {
      alert.error("Please request with right params");
    }
  };


  const unblockSelectNumbers = async () => {
    if (selectNumbers.length < 1)
      return alert.error("Please select at least one number");
    show();
    try{
      const res = await unblockedNumbers(selectNumbers);
      if (res.success) {
        alert.success("Number has been unblocked");
        await getBlockedNumbers(true);
      } else {
        alert.error(res.failure);
      }
    } catch (error) {
      console.log(error)
      alert.error(error?.message || "Network Error");
    } finally {
      hide();
    }
  };

  const _submitChange = async () => {
    if (!_checkPassword(password) || !_checkPassword(confirm_password)) {
      return alert.error("Password should at least 8 digits");

    } else if (password !== confirm_password) {
      return alert.error("Password doesn't match");
    } else {
      show();
      try {
        const res = await changePassword(password);
        if (res && !res.failure) {
          alert.success("Password Changed");
        } else {
          alert.error(res.failure);
        }
      } catch (error) {
        console.log(error)
        alert.error(error?.message || "Network Error");
      } finally {
        hide();
      }
    }
  };

  const focusInput = (key) => {
    inputRefs.current[key]?.focus();
  };

  return (
    <div id="account-wrapper">
      <div className="dashboard-sub-menu">
        <div className="sub-menu-title">MANAGE ACCOUNT</div>
        <div className="nav-wrapper">
          <div
            className={
              curSubMenu === "edit"
                ? "custom-nav-item  custom-nav-item-active"
                : "custom-nav-item"
            }
            onClick={() => selectSubMenu("edit")}
          >
            {curSubMenu === "edit" && <div className="left-bar"></div>}
            <div className="nav-icon">
              <img
                src={curSubMenu === "edit" ? PencilActiveIcon : PencilIcon}
                alt=""
              />
            </div>
            <div
              className={
                curSubMenu === "edit" ? "nav-name text-active" : "nav-name"
              }
            >
              Edit information
            </div>
          </div>
          <div
            className={
              curSubMenu === "blocked"
                ? "custom-nav-item  custom-nav-item-active"
                : "custom-nav-item"
            }
            onClick={() => selectSubMenu("blocked")}
          >
            {curSubMenu === "blocked" && <div className="left-bar"></div>}
            <div className="nav-icon">
              <img
                src={curSubMenu === "blocked" ? BanActiveIcon : BanIcon}
                alt=""
              />
            </div>
            <div
              className={
                curSubMenu === "blocked" ? "nav-name text-active" : "nav-name"
              }
            >
              Blocked numbers
            </div>
          </div>
          <div
            className={
              curSubMenu === "delete"
                ? "custom-nav-item  custom-nav-item-active"
                : "custom-nav-item"
            }
            onClick={() => selectSubMenu("delete")}
          >
            {curSubMenu === "delete" && <div className="left-bar"></div>}
            <div className="nav-icon">
              <img
                src={curSubMenu === "delete" ? TrashActiveIcon : TrashIcon}
                alt=""
              />
            </div>
            <div
              className={
                curSubMenu === "delete"
                  ? "nav-name delete-text text-active"
                  : "delete-text nav-name"
              }
            >
              Delete account
            </div>
          </div>
        </div>
      </div>

      <div className="app-board">
        {curSubMenu === "edit" ? (
          <div className="board-inner info-board">
            <div className="opreation-name">EDIT INFORMATION </div>
            <div className="personal op-area">
              <div className="label">PERSONAL INFORMATION</div>
              <div>
                <div className="fl1">
                  <div className="info_card">
                    <div className="icon">
                      <img src={UserIcon} alt="" />
                    </div>
                    <div className="info-card-body">
                      <div className="desc">First Name</div>
                      <input
                        type="text"
                        name="first_name"
                        required
                        className={`form-control ${
                          type === "detail" ? "" : "readonly"
                        }`}
                        defaultValue={first_name}
                        readOnly={type === "detail"}
                        onChange={handleInputChange}
                        ref={el => (inputRefs.current["info"] = el)}
                      />
                    </div>
                  </div>
                  <div className="info_card">
                    <div className="icon">
                      <img src={UserIcon} alt="" />
                    </div>
                    <div className="info-card-body">
                      <div className="desc">Last Name</div>
                      <input
                        type="text"
                        name="last_name"
                        required
                        className={`form-control ${
                          type === "detail" ? "" : "readonly"
                        }`}
                        defaultValue={last_name}
                        readOnly={type === "detail"}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  {type === "edit" ? (
                    <>
                      <button className="btn11" onClick={() => saveInfo()}>
                        SAVE INFO
                      </button>
                    </>
                  ) : (
                    <button
                      type="button"
                      className={"btn11"}
                      onClick={() => {
                        setType("edit");
                        focusInput("info");
                      }}
                    >
                      UPDATE
                    </button>
                  )}
                </div>
                <div className="fl1">
                <div
                  className={
                    profile && profile.is_verified
                      ? "verified-email fl2"
                      : "unverified-email fl2"
                  }
                >
                  <div className="info_card email">
                    <div className="icon">
                      <img src={AtIcon} alt="" />
                    </div>
                    <div className="info-card-body">
                      <div className="desc">Email Address</div>
                      <input
                          type="text"
                          name="email_address"
                          required
                          className={`form-control ${
                              emailType === "detail" ? "" : "readonly"
                          }`}
                          defaultValue={email}
                          readOnly={emailType === "detail"}
                          onChange={handleInputChange}
                          ref={el => (inputRefs.current["email"] = el)}
                      />
                    </div>
                  </div>

                  {profile && profile.is_verified ? (
                    <div className="is-verified">
                      <div className="result result1">Verified</div>
                    </div>
                  ) : (
                    <div className="is-verified">
                      <div className="result">Not verified</div>
                      <div className="desc">
                        Your email address has not been verified yet.
                      </div>

                      <div className="resend arrow_btn" onClick={_resendEvent}>
                        <span>RESEND EMAIL</span>
                        <img src={ArrowRight} alt="" />
                      </div>
                    </div>
                  )}
                </div>
                {emailType === "edit" ? (
                    <>
                        <button className="btn11" onClick={() => saveEmail()}>
                            SAVE EMAIL
                        </button>
                    </>
                ) : (
                    <button
                        type="button"
                        className={"btn11"}
                        onClick={() => {
                            setEmailType("edit");
                            focusInput("email");
                        }}
                    >
                        UPDATE
                    </button>
                )}
                </div>
              </div>
            </div>

            <div className="personal op-area">
              <div className="label">PASSWORD</div>
              <div>
                <div className="fl1">
                  <div className="info_card">
                    <div className="icon">
                      <img src={LockIcon} alt="" />
                    </div>
                    <div className="info-card-body">
                      <div className="desc">New Password</div>
                      <input
                        required
                        type={isReveal ? "text" : "password"}
                        className="form-control"
                        name="password"
                        value={password}
                        placeholder="At least 8 characters"
                        onChange={handleInputPwdChange}
                      />
                    </div>
                  </div>
                  <div className="info_card">
                    <div className="icon">
                      <img src={LockIcon} alt="" />
                    </div>
                    <div className="info-card-body">
                      <div className="desc">Retype Password</div>
                      <input
                        required
                        type={isCheckReveal ? "text" : "password"}
                        value={confirm_password}
                        className="form-control"
                        placeholder="At least 8 characters"
                        name="confirm_password"
                        onChange={handleInputPwdChange}
                      />
                    </div>
                  </div>

                  <button className="btn11" onClick={() => _submitChange()}>
                    CHANGE
                  </button>
                </div>
              </div>
            </div>
            <div className="personal op-area">
                <div className="label">VOICEMAIL GREETING</div>
                <div>
                    <div className="fl1">
                        <Upload ref={uploadRef} accept=".mp3" onSelect={setVoicemailFile} />
                        <button className="btn11" onClick={uploadVoicemail}>
                            UPLOAD
                        </button>
                    </div>
                </div>

            </div>
          </div>
        ) : curSubMenu === "blocked" ? (
          <div className="board-inner  blocked-board">
            <div className="opreation-name">BLOCKED NUMBERS </div>

            <div className="desc">
              Remove blocked contacts from the black list
            </div>
            <div className="op-blocked">
              <div className="blockedlist">
                {(!blockedNumbers || blockedNumbers.length <= 0) && <div style={{textAlign: "center", lineHeight: "40px"}}>
                  No Number Blocked
                </div> }
                {blockedNumbers && blockedNumbers.length > 0 && blockedNumbers.map((item) => {
                  return (
                    <div
                      key={item.phone_number}
                      onClick={() => selectNumber(item)}
                      className={
                        selectNumbers.findIndex((_item) => _item === item) > -1
                          ? "number-item number-item-active"
                          : "number-item"
                      }
                    >
                      <div className="number">{item.phone_number}</div>
                      <div className="check-cir">
                        <img
                          src={
                            selectNumbers.findIndex((_item) => _item === item) >
                            -1
                              ? CheckActiveIcon
                              : CheckIcon
                          }
                          alt=""
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
              <button className="btn11" onClick={unblockSelectNumbers}>
                UNBLOCK
              </button>
            </div>
          </div>
        ) : curSubMenu === "delete" ? (
          <div className="board-inner delete-board">
            <div className="opreation-name">DELETE ACCOUNT </div>
            <div className="desc">
              <div className="context">
                <p>
                  Deleting your account will erase all your data and login
                  information permanently.
                </p>
                <br />
                <p>Are you sure you want to proceed?</p>
              </div>
              <button type="button" className={"btn11"} onClick={clickDel}>
                DELETE
              </button>

              {showDelete && (
                <DeleteAccount
                  hide={!showDelete}
                  submit={() => {
                    handleDeleteAccount();
                  }}
                  close={() => {
                    setShowDelete(false);
                  }}
                />
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default AccountManage;

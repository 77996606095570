import React from "react";

export const Delete = (props) => {
    const { hide, submit, close } = props;

    return (
        <div className={`popup ${hide ? "hide": ""}`}>
            <div className="popup_inner text-center">
                <h1 className="text-center">Delete Account</h1>
                <p>Are you sure you want to delete your account? Once you delete your account, you will no longer have access to your number, contacts, messages, and voicemails. This action cannot be undone.</p>
                <div className="pull-right buttons">
                    <span className="del-popup-btn cancel-btn my-2" onClick={close}>
                        Cancel
                    </span>
                    <span className="warning-btn del-popup-btn my-2" onClick={submit}>
                        OK
                    </span>
                </div>
            </div>
        </div>
    );
};

export default Delete;

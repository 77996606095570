import React, { useState, useEffect } from "react";
import { logout } from "../services/AuthService";
import { useAtom } from "jotai";
import {
    ACTIVESUBSCRIPTIONS_ATOM,
    DEVICES_ATOM,
    PROFILE_ATOM,
    USER_ATOM,
} from "../Context";
import { HOME_URL, SITE_NAME } from "../AppConstants";
import MenuIcon from "@icons/menu.svg"
import HeaderLogo from '@icons/logo.svg';
import WhiteLogo from '@icons/logo_white.svg';
import "../style/Header.scss";
import "../style/Nav.scss";

export default function NavBar(props) {
    const { location, history, hasSub, className = "" } = props;
    const [user, setUser] = useAtom(USER_ATOM);
    const [, setActive] = useAtom(ACTIVESUBSCRIPTIONS_ATOM);
    const [, setDevice] = useAtom(DEVICES_ATOM);
    const [, setProfile] = useAtom(PROFILE_ATOM);
    const { pathname } = location;
    const [show, setShow] = useState(true);
    const [showRight, setShowRight] = useState(true);
    const _signOutEvent = async () => {
        history.push("./login");
        const res = await logout();
        setUser(null);
        setActive(null);
        setDevice(null);
        setProfile(null);
    };

    const _initNavBar = () => {
        // hide right or subtitle by url
        if (pathname.includes("/dashboard")) {
            setShowRight(false);
        }
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 900) {
                document.getElementById("nav") && document.getElementById("nav").classList.add("hide");
            } else {
                document.getElementById("nav") && document.getElementById("nav").classList.remove("hide");
            }
        };

        window.addEventListener("resize", handleResize);
        _initNavBar();
    }, [])

    return (
        <header className={showRight? `header row ${className}`: `header row ${className} device-header`}>
            <div className="left-logo">
                <a
                    href={`//${HOME_URL}`}
                >
                    {SITE_NAME === "SLYNUMBER" ? (
                        <h1>SLYNUMBER</h1>
                    ) : (
                        showRight? <img src={HeaderLogo} alt={"logo"} className={"mx-5"} /> : <img src={WhiteLogo} alt={"logo"} className={"mx-5"} />
                    )}
                </a>
            </div>

            {!showRight ? (
                <div className="subtitle">
                    <div className="stick">|</div>
                    <div className="context">Dashboard</div>
                </div>
            ) : (
                ""
            )}

            {user && user.user_token && showRight ? (
                <div className="header-right">
                    <nav
                        id={"nav"}
                        className={`nav ${show ? "hide" : ""}`}
                    >
                        <ul>
                            {hasSub ? (
                                <li>
                                    <div
                                        className={`btn`}
                                        onClick={() => {
                                            setShow(false);
                                            history.push("/dashboard/account");
                                        }}
                                    >
                                        Dashboard
                                    </div>
                                </li>
                            ) : (
                                ""
                            )}
                            {/* <li><a href="#"><img src="http://html2.upupload.com/66662/5segdmjirz66662/images/pic.png" alt="pic" /></a></li> */}
                            <li>
                                <div className="button-signout">
                                    <div className={`btn ${showRight ? "black-bg-btn" : "text-white"}`} onClick={_signOutEvent}>
                                        Sign out
                                    </div>
                                </div>
                            </li>

                            {/* <li><a href="#"><img src="http://html2.upupload.com/66662/5segdmjirz66662/images/icon1.png" alt="icon1" /><span>24</span></a></li> */}
                        </ul>
                    </nav>
                    <div className="menu" onClick={() => setShow(!show)}>
                        <img src={MenuIcon} alt={"menu icon"} />
                    </div>
                </div>
            ) : (
                ""
            )}
        </header>
    );
}

export const DOMAIN = process.env.DOMAIN || "https://slyfone.com:8060";
export const SITE_NAME = process.env.SITE_NAME || "SLYFONE";
export const PROFILE_KEY = process.env.PROFILE_KEY || "slyfone.user_profile";
export const SUBSCRIPTION_KEY = process.env.SUBSCRIPTION_KEY || "slyfone.subscription";
export const PLANS_KEY = process.env.PLANS_KEY || "slyfone.plans";

export const ACTIVE_SUB_KEY =
  process.env.ACTIVE_SUB_KEY || "slyfone.active_sub";
export const PHONE_NUMBER_KEY =
  process.env.PHONE_NUMBER_KEY || "slyfone.phone_number_choosen";
export const USER_INFO_KEY =
  process.env.USER_INFO_KEY || "slyfone.user_info_key";
export const TOKEN_KEY = process.env.TOKEN_KEY || "slyfone.token";
export const SELECT_PLAN_KEY =
  process.env.SELECT_PLAN_KEY || "slyfone.select_plan_key";
export const SELECT_PLAN_NAME_KEY =
  process.env.SELECT_PLAN_NAME_KEY || "slyfone.select_plan_name_key";
export const UUID_KEY = process.env.UUID_KEY || "slyfone.uuid";
export const HOME_URL = process.env.HOME_URL || "slyfone.com";
export const FB_APP_ID = process.env.REACT_APP_FB_APP_ID || "620051651930630"
export const APPLE_ID = process.env.REACT_APP_APPLE_ID || "com.slyfone.www"
export const GG_APP_ID = process.env.REACT_APP_GG_APP_ID || "503180233655-vqk0vosh95u7jjr4oe6fgio90dm9g4vb.apps.googleusercontent.com"
export const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI || "https://slyfone.com/app"
export const STRIPEKEY = process.env.REACT_STRIPEKEY || "pk_live_t4sUqft4SMty12798X7HKjl2"
export const DEVICES_KEY = process.env.DEVICES_KEY || "slyfone.devices";
export const CALLS_KEY = process.env.CALLS_KEY || "slyfone.calls";
export const CHATS_KEY = process.env.CALLS_KEY || "slyfone.chats";

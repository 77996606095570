import React, { useEffect, useState } from "react";
import NavBar from "../Components/NavBar";
import { loggedIn } from "../services/AuthService";
import { sendPasswordResetLink } from "../services/ApiService";
import { SITE_NAME } from "../AppConstants";
import { validateEmail } from "../Utils";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import { useAtom } from "jotai";
import { USER_ATOM } from "../Context";
import AtIcon from "@icons/at.svg";
import SlyfoneIcon from "@imgs/Slyfone_logo.png";

const ForgotPassword = (props) => {
    const history = useHistory();
    const alert = useAlert();
    const [email, setEmail] = useState();
    const [user] = useAtom(USER_ATOM);

    useEffect(() => {
        if (loggedIn() && user) {
            history.push("/dashboard/account");
        }
    }, []);

    const handleSubmit = () => {
        if (email && validateEmail(email)) {
            const res = sendPasswordResetLink(email);
            if (res && !res.failure) {
                alert.success(
                    `Password reset link sent to your email address ${email}`
                );
                history.push("./login");
            } else {
                alert.error(res.failure);
            }
        } else {
            alert.error("Please input vaild email address.");
        }
    };

    return (
        <div className="signup flex-container">
            <title>{SITE_NAME} | Forgot Password</title>

            <NavBar {...props} />
            <div className="main-content center">
                <div className="container">
                    <div className="row m-0">
                        <div className="login-form mx-auto">
                            <div className="center-logo">
                                <img src={SlyfoneIcon} alt="logo" />
                            </div>
                            <h1 className="signup-heading">FORGOT PASSWORD</h1>
                            <div>
                                <div className="order-block">
                                    <div className="row">
                                        <li className="col col-12">
                                            <div className="form-group">
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    name="email"
                                                    placeholder="Email"
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />
                                                <img
                                                    className="signup-icon"
                                                    src={AtIcon}
                                                    alt={"email-icon"}
                                                />
                                            </div>
                                        </li>
                                    </div>

                                    <div className="row">
                                        <button
                                            className="button w-100 text-center"
                                            onClick={() => handleSubmit()}
                                        >
                                            SEND LINK
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;

import React, { useEffect, useState } from "react";
import {
  activeSubscriptionDetail,
  cancelSubscription,
  getSubscription,
  stripePutMethod,
  upgradeSubscription,
  payAddon
} from "@services/ApiService";

import { loadStripe } from "@stripe/stripe-js";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  ElementsConsumer
} from "@stripe/react-stripe-js";

import { hide, show } from "react-global-loading";
import { useAlert } from "react-alert";
import { useAtom } from "jotai";
import { ACTIVESUBSCRIPTIONS_ATOM, PLANS_ATOM, SUBSCRIPTION_ATOM } from "@/Context";
import { useHistory } from "react-router-dom";
import Moment from "react-moment";
import PencilIcon from "@icons/pencil.svg";
import PencilActiveIcon from "@icons/pencil-2.svg";
import WalletIcon from "@icons/wallet.svg";
import WalletActiveIcon from "@icons/wallet-2.svg";
import CreditCardIcon from "@icons/credit-card.svg";
import CreditCardActiveIcon from "@icons/credit-card-2.svg";
import CheckCircleIcon from "@icons/check-circle-3.svg";
import CheckIcon from "@icons/check-circle.svg";
import StripeLogo from "@icons/stripe.svg";
import "../style/ManagePlans.scss";
import { STRIPEKEY } from "../AppConstants";

const ManagePlanForm = (props) => {
  const { stripe, elements } = props;
  const alert = useAlert();
  const history = useHistory();
  const [active, setActive] = useAtom(ACTIVESUBSCRIPTIONS_ATOM);
  const [subscription, setSubscription] = useAtom(SUBSCRIPTION_ATOM);
  const [remotePlans] = useAtom(PLANS_ATOM);

  const [curPlan, setCurPlan] = useState(null);
  const [selPlan, setSelPlan] = useState(null);
  const [plans, setPlans] = useState([]);

  const [curSubMenu, setSubMenu] = useState("edit");

  useEffect(async () => {
    show();
    const details = active;
    if ((details||[]).length > 0 && details[0].plan_id) {
      const detail = details[0];
      setCurPlan(detail);
      setSelPlan(detail);
      setPlans(remotePlans && remotePlans.filter((r) => r.new_total_amount > details[0].new_total_amount).sort((a, b) => a.new_total_amount - b.new_total_amount))
    }

    hide();
  }, []);

  const selectSubMenu = (key) => {
    setSubMenu(key);
  };

  const handleSubscription = async () => {
    show();
    try {
      const res = await upgradeSubscription(selPlan.plan_name_en);
      if (res && !res.failure) {
        const active = await activeSubscriptionDetail(true);
        if (active) {
          setActive([...active]);
          history.go(0);
        }
      } else {
        alert.error(res.failure);
      }
    } catch (error) {
      console.log(error)
      alert.error(error?.message || "Network Error");
    } finally {
      hide();
    }
  };

  const handleCancelSubscription = async () => {
    show();
    try {
      const res = await cancelSubscription(curPlan.subscription_id);
      if (res && !res.failure) {
        const active = await activeSubscriptionDetail(true);
        if (active) {
          setActive([...active]);
          history.go(0);
        }
      } else {
        alert.error(res.failure);
      }
    } catch (error) {
      console.log(error)
      alert.error(error?.message || "Network Error");
    } finally {
      hide();
    }
  };

  const formatPlanPrice = (mount, interval) => {
    let dollar = (mount / 100).toFixed(2);
    let _interval = interval;
    let arr = interval.split(" ");
    if (arr[0] === "1") {
      _interval = arr[1];
    }

    return (
      <div className="price">
        <span className="total_amount">${dollar}</span>
        <span>{` per ${_interval} `}</span>
      </div>
    );
  };

  const FormatRightList = (text) => {
    return text.split("• ").slice(1);
  };

  const handleSubmit = async (e) => {
    show();
    e.preventDefault();

    if (elements == null) {
      return;
    }
    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement),
      });

      if (!error) {
        const cardNumberElement = elements.getElement(CardNumberElement);
        const st = await stripe.createToken(cardNumberElement);
        if ((st||{}).error) {
          alert.error("Stripe Error");
        } else {
          const t = await stripePutMethod(st.token.id);
          if ((t||{}).failure) {
            alert.error(t.failure);
          } else {
              await activeSubscriptionDetail(true);
              await getSubscription(true);
              alert.info("Payment info changed");
          }
        }
      } else {
        alert.error(error.message);
      }
    } catch (error) {
      console.log(error);
      alert.error(error.message || "Network Error");
    } finally {
      hide();
    }
  };

  const handlePurchase = async () =>{
    if(subscription && subscription.payment_method === "stripe"){
      show();
      try {
        const a = await payAddon();
        if ((a||{}).success) {
          hide();
          history.push("/dashboard/account");
        } else {
          alert.error(a.failure);
        }
      } catch (error) {
        console.log(error);
        alert.error(error.message || "Network Error");
      } finally {
        hide();
      }
    } else {
      history.push("/add-credit")
    }
  }


  return (
    <div id="plan-wrapper">
      <div className="dashboard-sub-menu">
        <div className="sub-menu-title">MANAGE PLANS</div>
        <div className="custom-nav">
          <div
            className={
              curSubMenu === "edit"
                ? "custom-nav-item  custom-nav-item-active"
                : "custom-nav-item"
            }
            onClick={() => selectSubMenu("edit")}
          >
            {curSubMenu === "edit" && <div className="left-bar"></div>}
            <div className="nav-icon">
              <img
                src={curSubMenu === "edit" ? PencilActiveIcon : PencilIcon}
                alt=""
              />
            </div>
            <div
              className={
                curSubMenu === "edit" ? "nav-name text-active" : "nav-name"
              }
            >
              Change plan
            </div>
          </div>
          <div
            className={
              curSubMenu === "wallet"
                ? "custom-nav-item  custom-nav-item-active"
                : "custom-nav-item"
            }
            onClick={() => selectSubMenu("wallet")}
          >
            {curSubMenu === "wallet" && <div className="left-bar"></div>}
            <div className="nav-icon">
              <img
                src={curSubMenu === "wallet" ? WalletActiveIcon : WalletIcon}
                alt=""
              />
            </div>
            <div
              className={
                curSubMenu === "wallet" ? "nav-name text-active" : "nav-name"
              }
            >
              Manage credits
            </div>
          </div>
          <div
            className={
              curSubMenu === "credit"
                ? "custom-nav-item  custom-nav-item-active"
                : "custom-nav-item"
            }
            onClick={() => selectSubMenu("credit")}
          >
            {curSubMenu === "credit" && <div className="left-bar"></div>}
            <div className="nav-icon">
              <img
                src={
                  curSubMenu === "credit"
                    ? CreditCardActiveIcon
                    : CreditCardIcon
                }
                alt=""
              />
            </div>
            <div
              className={
                curSubMenu === "credit" ? "nav-name text-active" : "nav-name"
              }
            >
              Change payment info
            </div>
          </div>
        </div>
      </div>

      <div className="app-board">
        {curSubMenu === "edit" ? (
          <div className="board-inner info-board">
            <div className="opreation-name">CHANGE PLAN</div>
            <div className="personal op-area">
              {" "}
              {curPlan && (
                <div>
                  <div className="label">CURRENT PLAN</div>
                  <div className="fl">
                    <div className="plan-card">
                      <div className={"plan-type"}>{curPlan.plan_name_en}</div>
                      {formatPlanPrice(
                        curPlan.new_total_amount,
                        curPlan.plan_interval
                      )}
                      <div className="subscribe-time">
                        Subscribed on{" "}
                        {
                          <Moment interval={0} format="D-M-YYYY">
                            {curPlan.subscription_date}
                          </Moment>
                        }
                      </div>
                      <div className="desc">{curPlan.renew_en}</div>
                      <div className="right-list">
                        {FormatRightList(curPlan.description_en).map((item, index) => {
                          return (
                            <div className="right-item" key={index}>
                              <span className="icon">
                                <img src={CheckCircleIcon} alt="" />
                              </span>
                              <span className="detail">{item}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <button
                      className="btn11"
                      // disabled={subscription.status !== 'active'}
                      onClick={() => handleCancelSubscription()}
                    >
                      CANCEL PLAN
                    </button>
                  </div>
                </div>
              )}
              {plans && plans.length ? (
                <div>
                  <div className="label">UPGRADE PLAN</div>
                  <div className="fl">
                    <div>
                      {plans.map((item, i) => {
                        return (
                          <div
                            className="plan-card"
                            key={item.plan_id + i}
                            onClick={() => {
                              if (selPlan && item.plan_id === selPlan.plan_id) {
                                setSelPlan(null);
                              } else {
                                setSelPlan(item);
                              }
                            }}
                          >
                            {selPlan && item.plan_id === selPlan.plan_id && (
                              <div className="check-icon">
                                <img src={CheckIcon} alt="" />
                              </div>
                            )}
                            <div className={"plan-type"}>
                              {item.plan_name_en}
                            </div>
                            {formatPlanPrice(
                              item.new_total_amount,
                              item.plan_interval
                            )}

                            <div className="desc">{item.renew_en}</div>
                            <div className="right-list">
                              {FormatRightList(item.description_en).map(
                                (item) => {
                                  return (
                                    <div className="right-item">
                                      <span className="icon">
                                        <img src={CheckCircleIcon} alt="" />
                                      </span>
                                      <span className="detail">{item}</span>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    {selPlan && selPlan.plan_id !== curPlan.plan_id && (
                      <button
                        disabled={!selPlan || subscription.status === 'transition'}
                        className="btn11"
                        onClick={() => handleSubscription()}
                      >
                        {curPlan ? "UPGRADE" : "SUBSCRIBE"}
                      </button>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : curSubMenu === "wallet" ? (
          <div className="board-inner info-board">
            <div className="opreation-name">MANAGE CREDITS </div>
            <div className="credits-inner op-area">
              <pre className="desc">
                {`Purchase credits to be able to send messages and make calls.

Every credit is equivalent to either:
• 1 message
• 1-minute call`}
              </pre>
              <div className="fl">
                <div className="credits-card">
                  <div className="info">
                    <div className="name">1000 CREDITS</div>
                    <div className="desc">One time purchase</div>
                  </div>
                  <div className="price">$10</div>
                </div>
                <button
                  className="btn11"
                  onClick={() => handlePurchase()}
                >
                  PURCHASE
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="board-inner info-board">
            <div className="opreation-name">CHANGE PAYMENT INFO </div>
            <div className="card-inner op-area">
              <pre className="desc">
                {`Edit your payment information and save the changes`}
              </pre>
              <div>
                <form onSubmit={handleSubmit} className="fl">
                  <div className="card-form">
                    <div className="card-number-wrapper">
                      <CardNumberElement
                          className={"card-number card-form-item"}
                          options={{
                            showIcon: false,
                            iconStyle: "default",
                            placeholder: "",
                          }}
                      />
                      <img
                          src={CreditCardIcon}
                          className="card-number-icon"
                          alt=""
                      />
                    </div>
                    <div className="row-from">
                      <CardExpiryElement
                          className={"card-expiry card-form-item"}
                          options={{placeholder: "MM / YY"}}
                      />
                      <CardCvcElement
                          className={"card-cvc card-form-item"}
                          options={{ placeholder: "CVC" }}
                      />
                    </div>
                    <div className={"powered-by"}>
                      Powered by <img src={StripeLogo} alt={"stripe"} />{" "}
                    </div>
                  </div>
                  <button type="submit" disabled={!stripe} className="btn11">CHANGE</button>
                </form>

              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const InjectedManagePlanFormForm = (props) => (
    <ElementsConsumer>
      {({ stripe, elements }) => (
          <ManagePlanForm stripe={stripe} elements={elements} {...props} />
      )}
    </ElementsConsumer>
);

const stripePromise = loadStripe(STRIPEKEY);

const ManagePlan = (props) => (
  <Elements stripe={stripePromise}>
    <InjectedManagePlanFormForm {...props} />
  </Elements>
);

export default ManagePlan;

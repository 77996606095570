import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
    Elements,
    ElementsConsumer,
    PaymentRequestButtonElement
} from "@stripe/react-stripe-js";

import {PHONE_NUMBER_KEY, SELECT_PLAN_KEY, SITE_NAME, STRIPEKEY} from "../AppConstants";
import {
    activeSubscriptionDetail,
    getPhoneNumber,
    payAddon,
    renewSubscription,
    stripePutMethod,
    subscribeTo,
    upgradeSubscription,
} from "../services/ApiService";
import {
    ACTIVESUBSCRIPTIONS_ATOM,
    DEVICES_ATOM,
    SUBSCRIPTION_ATOM
  } from '../Context'
import ListIcon from "@/icons/list.svg";
import CardIcon from "@/icons/credit-card.svg";
import ApplePayIcon from "@/icons/applepay.svg";
import StripeIcon from "@/icons/stripe.svg";
import { show, hide } from "react-global-loading";
import { useAlert } from "react-alert";
import "@style/Payment.scss"


const CheckoutForm = (props) => {
    const { history, stripe, elements, location } = props;
    const { addon } = location.state || { addon: false }
    const alert = useAlert();
    const [active] = useAtom(ACTIVESUBSCRIPTIONS_ATOM);
    const [subscription] = useAtom(SUBSCRIPTION_ATOM);
    const [device] = useAtom(DEVICES_ATOM);

    const [plan, setPlan] = useState({});
    const [payment, setPayment] = useState("card")
    const [paymentRequest, setPaymentRequest] = useState(null);
    const [applePayToken, setApplePayToken] = useState(null);
    const [applePayer, setApplePayer] = useState(null);


    useEffect(() => {
         // Get phone number from session storage stored in are-code page/previous device info
        // Go back to area code page while no phone_number choose.
        const phone_number = sessionStorage.getItem(PHONE_NUMBER_KEY);
        if (!phone_number) {
        alert.error("No phone number choose");
        history.push("/area-code");
        }
        // Get the plan from session storage, go back to price page if no value supply.
        if (sessionStorage.getItem(SELECT_PLAN_KEY) === "undefined") {
            history.push("/pricing-page");
        } else {
            setPlan(JSON.parse(sessionStorage.getItem(SELECT_PLAN_KEY) || ""))
        }
        if (stripe) {
            const pr = stripe.paymentRequest({
                country: 'US',
                currency: 'usd',
                total: {
                    label: plan.plan_name_en,
                    amount: addon ? (plan.new_total_amount + 1000) : plan.new_total_amount,
                },
                requestPayerName: true,
                requestPayerEmail: true,
            });

            // Check the availability of the Payment Request API.
            pr.canMakePayment().then(result => {
                if (result && result.applePay) {
                    setPaymentRequest(pr);
                }
            });
            // Add listener to stripe Apple Pay selected.
            pr.on("token", (evt) => {
                setApplePayToken(evt.token.id)
                setApplePayer(
                    { email: evt.payerEmail, name: evt.payerName }
                )
                evt.complete("success");
            })
        }
    }, [stripe]);

    const handleSubmit = async (e) => {
        show();
        e.preventDefault();
        const phone_number = sessionStorage.getItem(PHONE_NUMBER_KEY);
        if (!phone_number) {
            alert.error("No phone number choose");
            history.push("./area-code");
        }

        if (elements == null) {
            alert.error("Stripe Not Init");
            return;
        }
        try {
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: "card",
                card: elements.getElement(CardNumberElement),
            });

            if (!error) {
                const cardNumberElement = elements.getElement(CardNumberElement);
                const st = await stripe.createToken(cardNumberElement);
                if (st && !st.error) {
                    const t = await stripePutMethod(st.token.id);
                    await handleSubscribe(t)
                } else {
                    alert.error("Stripe Error");
                }
            } else {
                alert.error(error.message);
            }
        } catch (error) {
            console.log(error);
            alert.error(error.message || "Network Error");
        } finally {
            hide();
        }
    };

    const handleApplePaySubmit = async (e) => {
        show()
        e.preventDefault();
        try {
            const t = await stripePutMethod(applePayToken);
            await handleSubscribe(t);
        } catch (error) {
            console.log(error);
            alert.error(error.message || "Network Error");
        } finally {
            hide();
        }
    }

    const handleSubscribe = async(t)=>{
         // Get Phone number from session storage
        const phone_number = sessionStorage.getItem(PHONE_NUMBER_KEY);
        if (!phone_number) {
            alert.error("No phone number choose");
            history.push("/area-code");
        }
        // Alert failure message while connect payment method to Backend
        if (t.failure) {
            alert.error(t.failure);
            hide();
            return;
        }
        const planName = plan.plan_name_en;
        let s
        if(subscription && subscription.status === 'transition'){
            s = await renewSubscription(planName)
        }else{
            if (active && active.length > 0){
                s = await upgradeSubscription(planName);
            }else{
                s = await subscribeTo(planName);
            }
        }

        if (s && s.success) {
            // Add addon while addon is chosen
            if (addon) {
                const a = await payAddon();
            }
            // Request the phone number choose in session storage,
            // not request this while account is in transition status.
            if(!device || (device && !device.phone_number)){
                const phone = await getPhoneNumber(phone_number);
            }
            // Get active subscribe info from Backend.
            const curActive = await activeSubscriptionDetail(true);
            if (curActive && !curActive.failure) {
                if (curActive.length > 0) {
                    const tx = curActive[0];
                    // send event to GTM
                    window.dataLayer.push({
                        event: "purchase",
                        ecommerce: {
                            transaction_id: tx.subscription_id,
                            value: tx.new_total_amount / 100,
                            currency: "USD",
                            items: [
                                {
                                    item_id: tx.stripe_plan_id,
                                    item_name: tx.plan_name_en,
                                    currency: "USD",
                                    price: tx.new_total_amount / 100,
                                    quantity: 1,
                                },
                            ],
                        },
                    });
                    hide();
                    if(subscription && subscription.status === "transition"){
                        history.push("/dashboard/account");
                    }
                    history.push("/subscribe_success");
                }
            } else {
                alert.error(curActive.failure);
            }
        } else {
            alert.error(s.failure);
        }
    }

    return (
        <div className="checkout-page flex-container mb-3">
            <title>
                {SITE_NAME} | {plan.plan_name_en}
            </title>
            <h1>Payment</h1>
            {(subscription && subscription.status === "transition" && device && device.phone_number) &&
                <div className="transition-warning mx-auto w-75 text-align-center my-2">
              Your plan has expired since we could not process payment. To keep your number and renew your subscription please update your payment information
            </div>}
            <div className={"checkout-content"}>
                <div className="checkout-box checkout-left">
                    <h2>Your {SITE_NAME} Purchase</h2>
                    <div className={"price"}>${addon ? (plan.new_total_amount + 1000) / 100 : plan.new_total_amount / 100} Today</div>
                    <div className={"price-hint"}><u>{plan.plan_name_en}{addon && `: $${plan.new_total_amount / 100}`}</u></div>
                    <div className={"price-detail"}>
                        {plan.plan_interval === "3 months"
                            ? "Billed every 3 months. Cancel any time."
                            : "Billed yearly."}
                    </div>
                    <div className={"price-list"}>
                        <div className={"price-item"}>
                            <img src={ListIcon} alt={"price-list"} />
                            <span>Private Inbound Mobile Number</span>
                        </div>
                        <div className={"price-item"}>
                            <img src={ListIcon} alt={"price-list"} />
                            <span>Voicemail Inbox</span>
                        </div>
                        <div className={"price-item"}>
                            <img src={ListIcon} alt={"price-list"} />
                            <span>Mute & Spam Blocking</span>
                        </div>
                        <div className={"price-item"}>
                            <img src={ListIcon} alt={"price-list"} />
                            <span>Caller ID with Name</span>
                        </div>
                        {addon ? <div className={"price-item"}>
                            <span><b><u>+ 1000 Outbound Credits: $10</u></b></span>
                        </div> : <div className={"price-item"}>
                            <img src={ListIcon} alt={"price-list"} />
                            <span>100 Free Outbound Credits</span>
                        </div>}
                    </div>
                </div>
                <div className="checkout-box checkout-right">
                    <h2>Choose How to Pay</h2>
                    <div className={"payment-list"}>
                        <img
                            className={payment === "card" ? "payment-active" : ""}
                            src={CardIcon}
                            alt={"credit card"}
                            onClick={() => setPayment("card")}
                        />
                        {paymentRequest && <img
                            className={payment === "applepay" ? "payment-active" : ""}
                            src={ApplePayIcon}
                            alt={"apple pay"}
                            onClick={() => setPayment("applepay")}
                        />}
                    </div>
                    {
                        payment === "card" && <>
                            <div className={"payment-extra"}>Pay With Card</div>
                            <form onSubmit={handleSubmit}>
                                <CardNumberElement
                                  className={"border-grey inner-text-padding"}
                                  options={{placeholder: ""}}
                                />
                                <CardCvcElement
                                  className={"border-grey inner-text-padding"}
                                  options={{placeholder: "CVC"}}
                                />
                                <CardExpiryElement
                                  className={"border-grey inner-text-padding"}
                                  options={{placeholder: "MM / YY"}}
                                />
                                <button type="submit" disabled={!stripe} className="stripe-button">
                                    Pay
                                </button>
                            </form>
                            <div className={"power-by"}>
                                Powered by <img src={StripeIcon} alt={"stripe"} />{" "}
                            </div>
                        </>
                    }
                    {
                        payment === "applepay" && <>
                            <div className={"payment-extra"}>Pay With Apple Pay</div>
                            <div id={"applepay-container"} className={"mt-2"}>
                                {applePayToken && applePayer ?
                                    (<>
                                        <hr />
                                        <div style={{ margin: "18px 0", fontSize: "16px" }}>
                                            <span><b>Apple Pay</b>: {applePayer.email}</span>
                                            <a onClick={() => {
                                                setApplePayToken(null);
                                                setApplePayer(null);
                                            }} style={{
                                                textDecoration: "underline",
                                                float: "right",
                                                fontSize: "14px",
                                                cursor: "pointer"
                                            }}>Cancel</a>
                                        </div>
                                        <div>
                                            <button type="button" disabled={!applePayToken} className="stripe-button"
                                                onClick={(e) => handleApplePaySubmit(e)}>
                                                Pay
                                            </button>
                                        </div>
                                        <hr />
                                    </>)
                                    :
                                    (paymentRequest && <PaymentRequestButtonElement options={{
                                        paymentRequest, style: {
                                            paymentRequestButton: {
                                                type: "default",
                                                theme: 'dark',
                                                height: '44px',
                                            },
                                        }
                                    }} className="w-100 my-2" />)}
                            </div>
                            <div className={"power-by"}>
                                Powered by <img src={StripeIcon} alt={"stripe"} />{" "}
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    );
};

const InjectedCheckoutForm = (props) => (
    <ElementsConsumer>
        {({ stripe, elements }) => (
            <CheckoutForm stripe={stripe} elements={elements} {...props} />
        )}
    </ElementsConsumer>
);

const stripePromise = loadStripe(STRIPEKEY);

const Payment = (props) => (
    <Elements stripe={stripePromise}>
        <InjectedCheckoutForm {...props} />
    </Elements>
);

export default Payment;
